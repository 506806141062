import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Table from '../../components/Table'
import { ApplicationEndpoint, makeRequest, showRequestError } from '../../config/endpoints';
import { iApplication } from '../../config/shared';
import { errorSwal, infoSwal } from '../../config/template';
import { deleteApplicationRequest, findAllApplicationRequest } from './Application.requests';
import { ApplicationCreateRoute } from './ApplicationCreate';
import { ApplicationViewRoute } from './ApplicationView';


const tableHeader = [
  { name: "#", style: "text-center" },
  { name: "Nome", },
  { name: "Criado", style: "text-center" },
  { name: "Ações", style: "text-center" },
];

export const ApplicationListRoute = "/applications"

function ApplicationList() {

  const [data, setData] = useState<iApplication[]>([])
  const [loading, setLoading] = useState(true)

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(data);


  const getApps = async() => {
    const {body,response} = await findAllApplicationRequest();
    response.ok ? setData(body) : setData([])


    setLoading(false)
    
  }



  useEffect(() => {
    getApps()
    return () => {}
  }, [])
  


  useEffect(() => {
    if (search.length !== 0) {
      setFilterData(
        data.filter((item) => {
          return item.id?.toString().toLowerCase().includes(search.toLowerCase()) ||
            item.name?.toLowerCase().includes(search.toLowerCase())
        })
      );
    }
    else {
      setFilterData(data);
    }

  }, [search,data])

  const deleteId = async (uuid :string) => {
    const shouldDelete = await infoSwal()

    if(shouldDelete.isConfirmed){

      const res = await deleteApplicationRequest(uuid)

      if (res.response.ok) {
        getApps()
      }
      else {
        showRequestError(res)
      }
      
    }
  }



  const renderTableBodyData = () => {
    return filterData.map((app: iApplication) => {
      return (
        <tr key={app.id}>
          <td className="text-center">{app.id}</td>
          <td>{app.name ?? ""}</td>
          <td className="text-center">{new Date(app?.createdAt).toDateString()}</td>
          <td className="text-center">
            <Link to={ApplicationViewRoute.replace(":uuid",app.uuid)} className="btn btn-sm btn-primary">
              <i className="fa fa-eye"></i> Ver
            </Link>
            {/* &nbsp;

            <Link to={`#`} className="btn btn-sm btn-warning">
              <i className="fa fa-edit"></i> Editar
            </Link> */}

            &nbsp;
            <button className="btn btn-sm btn-danger"
              onClick={() => deleteId(app.uuid)}>
              <i className="fa fa-fw fa-trash"></i> Eliminar
            </button>
          </td>
        </tr>
      )
    })
  }

  return (
    <>
      <h2 className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center content-heading">
        Lista de Applicações

        <Link className='btn btn-primary' to={ApplicationCreateRoute}>Criar</Link>
      </h2>


      <Table
        header={tableHeader}
        renderTableBodyData={renderTableBodyData}
        data={filterData}
        search={search}
        setSearch={setSearch}
        loading={loading}
      />

    </>
  )
}

export default ApplicationList