import React, { Fragment, useContext, useEffect, useState } from 'react'
import Table from '../../components/Table';
import { getSysLogsRequest, LoggingEndpoint, makeRequest } from '../../config/endpoints';
import { LogType } from '../../config/shared';
import { UserContext } from '../../hooks/UserContext';
import { sleep } from '../../utils/Sleep';

export const SystemLogsPageRoute = "/SystemLogs"


function SystemLogsPage() {


    const tableHeader = [
        { name: "#", style: "text-center" },
        { name: "Type", },
        { name: "Data", style: "text-center" },
        { name: "Mensagem", },
        { name: "raw/trace", },
    ];

    const { darkMode } = useContext(UserContext)


    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any[]>([])
    const [limit, setLimit] = useState<number>(50);



    const fetchData = async () => {
        console.log(`limit : `, limit);

        setLoading(true)
        await sleep(1000)

        const {response, body} =  await getSysLogsRequest(limit)
        console.log(response);
        response.ok ? setData(body) : setData([]);

        setLoading(false)
    }

    useEffect(() => {
        fetchData();
        return () => {
            setData([]);
        }
    }, [limit])



    return (
        <div>
            <h1>System Logs</h1>

            <p>
                <button className='btn btn-sm btn-primary mx-2' onClick={fetchData}>
                    <i className="fa fa-refresh"></i> Refresh
                </button>

                {limit !== 0 &&
                    <button className='btn btn-sm btn-primary mx-2' onClick={() => setLimit(0)}>
                        <i className="fa fa-th-list"></i> Load All
                    </button>
                }
            </p>


            <Table
                header={tableHeader}
                renderTableBodyData={() =>
                    data.map(item => {
                        let badgeColor = "bg-success";
                        let rowColor = "table-success";

                        if (item["logType"] == LogType.INFO) {
                            badgeColor = "bg-info";
                            rowColor = "table-info";
                        }
                        else if (item["logType"] == LogType.DEBUG) {
                            // badgeColor = "bg-black-50";
                            // rowColor = "bg-black-25";

                            badgeColor = "bg-elegance";
                            rowColor = darkMode ? "bg-gd-elegance" : "bg-elegance-lighter";
                        }
                        else if (item["logType"] == LogType.WARNING) {
                            badgeColor = "bg-warning";
                            rowColor = "table-warning";
                        }
                        else if (item["logType"] == LogType.ALERT) {
                            badgeColor = "bg-warning";
                            rowColor = "table-warning";
                        }
                        else if (item["logType"] == LogType.DANGER) {
                            badgeColor = "bg-danger";
                            rowColor = "table-danger";
                        }

                        const emptyRawData = (Object.keys(item["rawData"]).length === 0);
                        const emptyTraceData = (Object.keys(item["trace"]).length === 0);


                        return (
                            <Fragment key={item["id"]}>
                                <tr className={rowColor}>
                                    <td className="text-center fw-bold">{item["id"] ?? "?"}</td>
                                    <td className="">
                                        <span className={`badge ${badgeColor} w-100`}>{item["logType"] ?? ""}</span>
                                    </td>
                                    <td className="text-center">{new Date(item["date"]).toLocaleString()}</td>
                                    <td>{item["message"] ?? ""}</td>
                                    <td>
                                        {!emptyRawData &&
                                            <button className="btn btn-primary btn-sm" type="button"
                                                data-bs-toggle="collapse" data-bs-target={`#raw_${item["id"]}`}
                                                aria-expanded="false" aria-controls={`raw_${item["id"]}`}>
                                                <i className="fa fa-eye"></i> Raw
                                            </button>
                                        }

                                        {!emptyTraceData &&
                                            <button className="btn btn-danger btn-sm" type="button"
                                                data-bs-toggle="collapse" data-bs-target={`#trace_${item["id"]}`}
                                                aria-expanded="false" aria-controls={`trace_${item["id"]}`}>
                                                <i className="fa fa-bug"></i> Trace
                                            </button>
                                        }


                                    </td>
                                </tr>
                                {!emptyRawData &&
                                    <tr className='collapse' id={`raw_${item["id"]}`}>
                                        <td className='text-break' colSpan={5}>

                                            <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(item["rawData"], null, 2) ?? ""}</pre>

                                        </td>
                                    </tr>
                                }

                                {!emptyTraceData &&
                                    <tr className='collapse' id={`trace_${item["id"]}`}>
                                        <td className='text-break' colSpan={5}>
                                            <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(item["trace"], null, 2) ?? ""}</pre>
                                        </td>
                                    </tr>
                                }

                            </Fragment>
                        )

                    })
                }
                data={data}
                loading={loading}
                striped={false}
            />


        </div>
    )
}

export default SystemLogsPage

// A parte da LogTable pode ser reutilizável juntamente com o que está em LicenseView