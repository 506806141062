import { kStringMaxLength } from 'buffer';
import React, { useEffect, useState } from 'react'
import { appendErrors, Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { JsonEditorControlled } from '../../components/JsonEditor';
import ReactSelectControlled from '../../components/ReactSelect';
import { showRequestError } from '../../config/endpoints';
import { iApplication, versionRegex } from '../../config/shared';
import { errorSwal } from '../../config/template';
import { DebugObject } from '../../utils/Debug';
import { ISelectLabelValue } from '../Licenses/LicenseCreate';
import { createVersionRequest, viewApplicationRequest } from './Application.requests';
import { ApplicationViewRoute } from './ApplicationView';



export const ApplicationCreateVersionRoute = "/applications/view/:uuid/version"


type Inputs = {
    version: string,
    versionBase: ISelectLabelValue;
    parameters: string,
};


interface createRequest {
    number: string;
    applicationId: string
    parameters?: string;
}


function ApplicationCreateVersion() {



    const navigate = useNavigate();



    const { uuid } = useParams()
    const [app, setApp] = useState<iApplication | undefined | null>(undefined)


    const getApp = async () => {
        const { body, response } = await viewApplicationRequest(uuid ?? "");
        response.ok ? setApp(body) : setApp(null);
    }

    useEffect(() => {
        getApp()
        return () => { setApp(undefined) }
    }, [])


    const versionOptions = [
        { value: undefined, label: "Nova Versão" },
        ...app?.versions?.map(v => {
            return { value: v.uuid, label: v.number }
        }) ?? []
    ]



    const form = useForm<Inputs>({
        mode: "all",
        defaultValues: {
            version: "0.0.0",
            parameters: "",
            versionBase: versionOptions[0],
        },
    });

    const { register, control, handleSubmit, watch, formState: { errors }, setValue, resetField } = form

    const watchForm = watch();


    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        const sendData: createRequest = {
            applicationId: app!.uuid,
            number: data.version,
            parameters: data.parameters,
        }

        const { body, response } = await createVersionRequest(sendData);
        if (response.ok && body.uuid) {
            navigate(ApplicationViewRoute.replace(":uuid", app!.uuid))
        }
        else {
            showRequestError({ body, response })
        }
    };



    useEffect(() => {

        if (watchForm?.versionBase?.value) {
            const version = app?.versions?.find(v => v.uuid === watchForm.versionBase?.value);
            setValue("parameters", JSON.stringify(version?.parameters, null, 2))
        }
        else {
            resetField("parameters")
        }
        return () => {
            resetField("parameters")
        }
    }, [watchForm.versionBase])


    if (app == null) {
        return (
            <h1>APP NOT FOUND!</h1>
        )
    }




    return (
        <>
            <h2 className="content-heading">{app?.name}</h2>
            <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>

                {/* version */}
                <div className='block block-rounded'>
                    <div className='block-header block-header-default'>
                        <h3 className="block-title">Nova Versão</h3>
                    </div>

                    <div className='block-content'>

                        {/* version */}
                        <div className="mb-4">
                            <label className="form-label" htmlFor="name">Versão</label>
                            <input type="text" className={`form-control ${errors.version && "is-invalid"}`}
                                placeholder="Número da Versão"
                                {...register("version", {
                                    required: true, minLength: 1, pattern: versionRegex,
                                    validate: {
                                        exists: value => !app?.versions?.find(v => v.number === value)
                                    }

                                })} />

                            {errors.version && <div className="invalid-feedback">Versão Inválida</div>}
                        </div>


                        {/* version_base */}
                        <div className='mb-4'>
                            <label className="form-label" htmlFor="version">Versão Base</label>
                            <ReactSelectControlled name='versionBase' form={form} options={versionOptions}/>
                            {errors.versionBase && <div className="invalid-feedback">{errors.versionBase?.message}</div>}
                        </div>


                        {/* <div className='mb-4'>
                            <label className="form-label" htmlFor="textarea">Parâmetros</label>
                            <textarea className={`form-control ${errors.parameters && "is-invalid"}`} rows={10} 
                            {...register("parameters")}/>
                        </div> */}

                        <div className='mb-4'>
                            <label className="form-label" htmlFor="textarea">Parâmetros</label>
                            <JsonEditorControlled
                                name='parameters'
                                form={form}
                            />
                            {errors.parameters && <div className="invalid-feedback d-block">{errors.parameters?.message}</div>}
                        </div>

                    </div>
                </div>


                <div className="mb-4 ">
                    <button type="submit" className='btn btn-primary'>
                        Submeter
                    </button>
                </div>

            </form>

        </>
    )
}

export default ApplicationCreateVersion