import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';



interface iProps {
    id: string,
    title?: string,
    body?: React.ReactNode,
    size?: "modal-sm" | "normal" | "modal-lg" | "modal-xl" | "modal-fullscreen",

    backdrop?: boolean | "static",
    fade?: boolean,
    effect?: "modal-dialog-popin" | "modal-dialog-popout" | "modal-dialog-slideup" | "modal-dialog-slideright" | "modal-dialog-slideleft" | "modal-dialog-fromright" | "modal-dialog-fromleft" | "modal-dialog-top",

    defaultButtonActive?: boolean,
    defaultButtonText?: string,
    defaultButtonClass?: string,

    onConfirmCallback?: Function,
}


const BsModal = (props: iProps) => {

    const {
        id = "Modal1",
        title = "",
        body = (<></>),
        size = "normal",


        backdrop = true,
        fade = false,
        effect = undefined,

        defaultButtonActive = false,
        defaultButtonText = "Open Modal",
        defaultButtonClass = "btn btn-alt-primary",

        onConfirmCallback = async () => null
    } = props

    // close modal 
    const closeRef = React.createRef<HTMLButtonElement>();
    const closeModal = () => closeRef.current?.click();


    //
    const onConfirm = async () => {
        await onConfirmCallback();
        closeModal();
    }

    return (

        <>
            {defaultButtonActive &&
                <button type="button"
                    className={defaultButtonClass}
                    data-bs-toggle="modal"
                    data-bs-target={`#${id}`}>
                    {defaultButtonText}
                </button>
            }


            <div className={`modal ${fade && "fade"}`} id={`${id}`} tabIndex={-1} aria-labelledby={`${id}`} style={{ display: "none" }} aria-hidden="true" data-bs-backdrop={backdrop}>
                <div className={`modal-dialog ${size} ${fade && effect}`} role="document">
                    <div className="modal-content">
                        <div className="block block-rounded shadow-none mb-0">
                            <div className="block-header block-header-default">
                                <h3 className="block-title">{title}</h3>

                                <div className="block-options">
                                    <button type="button" className="btn-block-option" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>


                            <div className="block-content fs-sm">
                                {body}
                            </div>



                            <div className="block-content block-content-full block-content-sm text-end border-top">
                                <button type="button" className="btn btn-alt-secondary" onClick={closeModal}>
                                    Close
                                </button>

                                <button type="button" className="btn btn-alt-primary" onClick={onConfirm}>
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )


    /*
return (
    <>
        <Button variant="primary" onClick={handleShow}>
            Launch static backdrop modal
        </Button>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            // style={{backgroundColor: "white"}}
            // style={{backgroundColor: '#f6f7f9'}}
        >
            <Modal.Header closeButton>
                <Modal.Title>Modal title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                I will not close if you click outside me. Don't even try to press
                escape key.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary">Understood</Button>
            </Modal.Footer>
        </Modal>
    </>
)
*/
}

export default BsModal









// TODO :  remove top if not in use


// in use






interface iModalHookPropsOld {
    id: string,
    title?: string,
    body?: React.ReactNode,
    bottom?: React.ReactNode|null,
    size?: "modal-sm" | "normal" | "modal-lg" | "modal-xl" | "modal-fullscreen",
    btnConfirmText? :string

    backdrop?: boolean | "static",
    fade?: boolean,
    effect?: "modal-dialog-popin" | "modal-dialog-popout" | "modal-dialog-slideup" | "modal-dialog-slideright" | "modal-dialog-slideleft" | "modal-dialog-fromright" | "modal-dialog-fromleft" | "modal-dialog-top",

    onConfirmCallback?: Function,
}

type useModalHookReturnOld = [
    JSX.Element, //Modal
    () => void,  //openModal
    () => void,  //closeModal
    React.Dispatch<React.SetStateAction<React.ReactNode>>, //setModalBody
]

export const useModalHookOld = (props :iModalHookPropsOld): useModalHookReturnOld => {
    const openRef = React.createRef<HTMLButtonElement>();
    const openModal = () => openRef.current?.click();

    const closeRef = React.createRef<HTMLButtonElement>();
    const closeModal = () => {closeRef.current?.click(); setModalBody(<></>)};


    const {
        id = "Modal1",
        title = "",
        body = (<></>),
        bottom = null,
        size = "normal",
        btnConfirmText = "Done",


        backdrop = true,
        fade = false,
        effect = undefined,

        onConfirmCallback,

    } = props

    const [modalBody, setModalBody] = useState<React.ReactNode>(body)



    const onConfirm = async () => {
        if(!onConfirmCallback) closeModal();
        else await onConfirmCallback();
    }

    const Modal = (
        <>
            <button type="button" className="d-none"
                data-bs-toggle="modal"
                data-bs-target={`#${id}`}
                ref={openRef}>
            </button>


            <div className={`modal ${fade && "fade"}`} id={`${id}`} tabIndex={-1} aria-labelledby={`${id}`} style={{ display: "none" }} aria-hidden="true" data-bs-backdrop={backdrop}>
                <div className={`modal-dialog ${size} ${fade && effect}`} role="document">
                    <div className="modal-content">
                        <div className="block block-rounded shadow-none mb-0">
                            <div className="block-header block-header-default">
                                <h3 className="block-title">{title}</h3>

                                <div className="block-options">
                                    <button type="button" className="btn-block-option" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>


                            <div className="block-content fs-sm my-3">
                                {modalBody}
                            </div>



                            <div className="block-content block-content-full block-content-sm text-end border-top">
                                { bottom != null 
                                ? bottom : 
                                <>
                                <button type="button" className="btn btn-alt-secondary mx-2" onClick={closeModal}>
                                    Close
                                </button>

                                <button type="button" className="btn btn-alt-primary mx-2" onClick={onConfirm}>
                                    {btnConfirmText}
                                </button>
                                
                                </>
                                
                                }

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

    


    return [
        Modal,
        openModal,
        closeModal,
        setModalBody,
    ]

}











// TODO : not in use


interface iModalHookProps {
    id: string,
    title?: string,
    body?: React.ReactNode,
    bottom?: React.ReactNode|null,
    size?: "modal-sm" | "normal" | "modal-lg" | "modal-xl" | "modal-fullscreen",

    backdrop?: boolean | "static",
    fade?: boolean,
    effect?: "modal-dialog-popin" | "modal-dialog-popout" | "modal-dialog-slideup" | "modal-dialog-slideright" | "modal-dialog-slideleft" | "modal-dialog-fromright" | "modal-dialog-fromleft" | "modal-dialog-top",

}

type useModalHookReturn = [
    JSX.Element, //Modal
    () => void,  //openModal
    () => void,  //closeModal
    React.Dispatch<React.SetStateAction<React.ReactNode>>, //setModalBody
]

export const useModalHook = (props :iModalHookProps): useModalHookReturn => {
    const openRef = React.createRef<HTMLButtonElement>();
    const openModal = () => openRef.current?.click();

    const closeRef = React.createRef<HTMLButtonElement>();
    const closeModal = () => {closeRef.current?.click(); setModalBody(<></>)};


    const {
        id = "Modal1",
        title = "",
        body = (<></>),
        bottom = null,
        size = "normal",

        backdrop = true,
        fade = false,
        effect = undefined,

    } = props

    const [modalBody, setModalBody] = useState<React.ReactNode>(body)


    const Modal = (
        <>
            <button type="button" className="d-none"
                data-bs-toggle="modal"
                data-bs-target={`#${id}`}
                ref={openRef}>
            </button>


            <div className={`modal ${fade && "fade"}`} id={`${id}`} tabIndex={-1} aria-labelledby={`${id}`} style={{ display: "none" }} aria-hidden="true" data-bs-backdrop={backdrop}>
                <div className={`modal-dialog ${size} ${fade && effect}`} role="document">
                    <div className="modal-content">
                        <div className="block block-rounded shadow-none mb-0">
                            <div className="block-header block-header-default">
                                <h3 className="block-title">{title}</h3>

                                <div className="block-options">
                                    <button type="button" className="btn-block-option" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>


                            <div className="block-content fs-sm my-3">
                                {modalBody}
                            </div>



                            <div className="block-content block-content-full block-content-sm text-end border-top">
                                <button type="button" className="btn btn-alt-secondary mx-2" onClick={closeModal}>
                                    Close
                                </button>

                                { bottom }

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

    


    return [
        Modal,
        openModal,
        closeModal,
        setModalBody,
    ]

}






