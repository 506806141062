import { useContext, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import { AuthEndpoint, doUserLogin, makeRequest } from "../config/endpoints";
import { sidebar_close, sidebar_open } from "../config/template";
import { UserContext } from "../hooks/UserContext";
import { sleep } from "../utils/Sleep";


export const LoginPageRoute = "/Login"


interface Inputs {
    username: string,
    password: string,

    error: boolean,
    loading: boolean,
}

function LoginPage() {

    useEffect(() => {
        document.getElementById("page-container")?.classList.remove("sidebar-o")
    }, [])
    

    const { setUserAccount } = useContext(UserContext)

    const { register, control, handleSubmit, watch, formState: { errors }, setValue, resetField } = useForm<Inputs>({
        mode: "all",
        defaultValues: {
            error: false,
            loading: false,
        }
    })

    const watchForm = watch();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {

        setValue("loading", true);
        setValue("error", false);

        const response = await doUserLogin(data);
        
        if (!response.response.ok) {
            console.log(response);
            setValue("error", true);
        }
        else {
            document.getElementById("page-container")?.classList.add("sidebar-o")
            setUserAccount(response.body.access_token, response.body.user)
        }


        setValue("loading", false)

    }




    return (

        <div className="bg-image" style={{ backgroundImage: `url('assets/media/photos/photo34@2x.jpg')` }}>
            <div className="row mx-0 bg-black-50">
                <div className="hero-static col-md-6 col-xl-8 d-none d-md-flex align-items-md-end">
                    <div className="p-4">
                        <p className="fs-3 fw-semibold text-white">
                            Get Inspired and Create.
                        </p>
                        <p className="text-white-75 fw-medium">
                            Copyright &copy; Empty<span data-toggle="year-copy"></span>
                        </p>
                    </div>
                </div>



                <div className="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-body-extra-light">
                    <div className="content content-full">
                        {/* <!-- Header --> */}
                        <div className="px-4 py-2 mb-4">
                            <a className="link-fx fw-bold" href="index.html">
                                <i className="fa fa-key text-primary"></i>
                                &nbsp;
                                <span className="fs-4 text-dual">Cert</span><span className="fs-4 text-primary">SYS</span>
                            </a>
                            <h1 className="h3 fw-bold mt-4 mb-2">Welcome to Your Dashboard</h1>
                            <h2 className="h5 fw-medium text-muted mb-0">Please sign in</h2>


                        </div>
                        {/* <!-- END Header --> */}

                        <form className="px-4" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                            <div className="form-floating mb-4">
                                <input type="text" className={`form-control ${errors.username && "is-invalid"}`}
                                    disabled={watchForm.loading}
                                    id="username"
                                    placeholder="Enter your username"
                                    {...register("username", { required: true, value: "" })} />
                                <label className="form-label" htmlFor="username">Username</label>
                                {errors.username && <div className="invalid-feedback animated fadeIn">Username Invalid</div>}
                            </div>

                            <div className="form-floating mb-4">
                                <input type="password" className={`form-control ${errors.password && "is-invalid"}`}
                                    disabled={watchForm.loading}
                                    id="password"
                                    placeholder="Enter your username"
                                    {...register("password", { required: true, value: "" })} />
                                <label className="form-label" htmlFor="password">Password</label>
                                {errors.password && <div className="invalid-feedback animated fadeIn">Password Invalid</div>}
                            </div>

                            <div className="mb-4">
                                <button type="submit" className="btn btn-lg btn-alt-primary fw-semibold"
                                    disabled={watchForm.loading}
                                >
                                    Sign In
                                </button>
                            </div>

                            <h2 className="h5 fw-medium text-danger mb-0 text-danger">{watchForm.error ? "Invalid Login" : ""}</h2>

                        </form>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default LoginPage