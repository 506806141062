import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { iApplication, iClient, iLicense } from '../../config/shared';
import { errorSwal } from '../../config/template';
import { createLicensesRequest } from './License.requests';
import { LicenseViewRoute } from './LicenseView';

import Flatpickr from "react-flatpickr";
import "../../components/flatpickr.min.css"

import Select, { OptionsOrGroups } from 'react-select';

import { dateIsValid, inputDateToDate } from '../../utils/Date';
import { findAllClientsRequest } from '../Clients/Client.requests';
import { findAllApplicationRequest } from '../Applications/Application.requests';
import { parseParamsToText } from '../../utils/Params';
import { showRequestError } from '../../config/endpoints';
import { JsonEditorControlled } from '../../components/JsonEditor';
import ReactSelectControlled from '../../components/ReactSelect';
import { DebugObject } from '../../utils/Debug';


export const LicenseCreateRoute = "/licenses/create"

export interface ISelectLabelValue {
    label: string,
    value: any,
}

interface Inputs {
    issueDate: Date;
    expirationDate: Date;
    url? :string;
    client: string
    application: string;
    version: string
    parameters?: string;

    _client: ISelectLabelValue;
    _application: ISelectLabelValue;
    _version: ISelectLabelValue;
}

function LicenseCreate() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [clients, setClients] = useState<iClient[]>([])
    const [apps, setApps] = useState<iApplication[]>([])

    const getData = async () => {
        const clients = await findAllClientsRequest()
        clients.response.ok ? setClients(clients.body) : setClients([])

        const apps = await findAllApplicationRequest();
        apps.response.ok ? setApps(apps.body) : setApps([])

        setLoading(false)
    }

    useEffect(() => {
        getData();
        return () => {
            setClients([]);
            setApps([]);
        }
    }, [])

    
    
    const form = useForm<Inputs>({mode: "all"});
    const { register, control, handleSubmit, watch, formState: { errors }, setValue, resetField } = form

    const watchForm = watch();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        data.issueDate = inputDateToDate(data.issueDate.toString())
        data.expirationDate = inputDateToDate(data.expirationDate.toString())
        data.client = data._client.value
        data.application = data._application.value
        data.version = data._version.value

        
        const res = await createLicensesRequest(data);
        if(res.response.ok && res.body.uuid){
            navigate(LicenseViewRoute.replace(":uuid", res.body.uuid))
        }
        else{
            showRequestError(res);
        }

    };



    const clientOptions: ISelectLabelValue[] = clients.map((client) => {
        return { value: client.uuid, label: client.name }
    });

    const appOptions: ISelectLabelValue[] = apps.map((app) => {
        return { value: app.uuid, label: app.name }
    });

    const [versionOptions, setVersionOptions] = useState<ISelectLabelValue[]>([])


    const setUsableVersions = () => {
        let value: ISelectLabelValue[] = []

        if (watchForm?._application?.value) {
            const app = apps.find(a => a.uuid === watchForm?._application?.value)
            if (app && app.versions) {
                value = app.versions?.map(v => {
                    return { value: v.uuid, label: v.number }
                })
            }
        }
        setVersionOptions(value)
    }

    useEffect(() => {
        resetField("_version")
        resetField("parameters")
        setUsableVersions()
        return () => { setVersionOptions([]) }
    }, [watchForm._application])

    useEffect(() => {
        if (watchForm?._version?.value) {
            const version = apps.find(a => a.uuid === watchForm?._application?.value)!
                .versions?.find(v => v.uuid === watchForm._version?.value);

            // setValue("parameters",parseParamsToText(version?.parameters))
            setValue("parameters", JSON.stringify(version?.parameters,null,2))
            
        }
        else{
            resetField("parameters")
        }
        return () => {
            resetField("parameters")
        }
    }, [watchForm._version])


    // RENDER

    if (loading) {
        return (
            <h1>LOADING....</h1>
        )
    }


    return (
        <>
            <h2 className="content-heading">{"Criar Nova Licença"}</h2>
            <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>

                {/* info */}
                <div className='block block-rounded'>
                    <div className='block-header block-header-default'>
                        <h3 className="block-title">Informações</h3>
                    </div>

                    <div className='block-content'>

                        <div className="mb-4">
                            <label className="form-label" htmlFor="name">Data de Início</label>
                            <input type="date" className={`form-control ${errors.issueDate && "is-invalid"}`}
                                {...register("issueDate", { required: "Campo Obrigatório" })}
                            />
                            {errors.issueDate && <div className="invalid-feedback">{errors.issueDate?.message}</div>}
                        </div>

                        <div className="mb-4">
                            <label className="form-label" htmlFor="name">Data de Fim</label>
                            <input type="date" className={`form-control ${errors.expirationDate && "is-invalid"}`}
                                {...register("expirationDate", {
                                    required: false,
                                    validate: {
                                        DataInfriorDataInicio: value => {
                                            if (!dateIsValid(value)) { return true }
                                            if (inputDateToDate(value.toString()) < inputDateToDate(watchForm.issueDate.toString())) {
                                                return "Data de Fim infrior a Data de Início"
                                            }
                                            return true
                                        }
                                    },

                                })}
                            />
                            {/* {errors.expirationDate && <div className="invalid-feedback">{errors.expirationDate?.message} ({errors.expirationDate?.type})</div>} */}
                            {errors.expirationDate && <div className="invalid-feedback">{errors.expirationDate?.message})</div>}

                        </div>


                        {/* client */}
                        <div className='mb-4'>
                            <label className="form-label" htmlFor="client">Cliente</label>
                            <ReactSelectControlled name='_client' form={form} options={clientOptions} />
                            {errors._client && <div className="invalid-feedback">{errors._client?.message}</div>}
                        </div>

                        {/* app */}
                        <div className='mb-4'>
                            <label className="form-label" htmlFor="application">Applicação</label>
                            <ReactSelectControlled name='_application' form={form} options={appOptions} />
                            {errors._application && <div className="invalid-feedback">{errors._application?.message}</div>}
                        </div>

                        {/* version */}
                        <div className='mb-4'>
                            <label className="form-label" htmlFor="version">Versão</label>
                            <ReactSelectControlled name='_version' form={form} options={versionOptions} />
                            {errors._version && <div className="invalid-feedback">{errors._version?.message}</div>}
                        </div>

                        {/* Parameters */}
                        {/* <div className='mb-4'>
                            <label className="form-label" htmlFor="textarea">Parâmetros</label>
                            <textarea className={`form-control ${errors.parameters && "is-invalid"}`} rows={5}
                                {...register("parameters")} />
                        </div> */}

                        <div className='mb-4'>
                            <label className="form-label" htmlFor="textarea">Parâmetros</label>
                            <JsonEditorControlled
                                name='parameters'
                                form={form}
                            />
                            {errors.parameters && <div className="invalid-feedback d-block">{errors.parameters?.message}</div>}
                        </div>



                        <div className="mb-4">
                            <label className="form-label" htmlFor="url">URl da Applicação</label>
                            <input type="text" className={`form-control ${errors.url && "is-invalid"}`}
                                placeholder="URL da applicação"
                                {...register("url", { required: false})} />
                            {errors.url && <div className="invalid-feedback">URL Inválida</div>}
                        </div>
                    </div>
                </div>



                {/* submit form */}
                <div className="mb-4 ">
                    <button 
                        type="submit" 
                        className='btn btn-primary'
                    >
                        Submeter
                    </button>
                </div>

                {/* {JSON.stringify(watchForm)} */}
            </form>
        </>
    )
}

export default LicenseCreate
