export const DebugObject = (object: any, name: string = "") => {
    return (
        <div className="block block-themed m-4">
            <div className="block-header bg-gd-cherry">DEBUG {name.length > 0 && `(${name})`}:</div>
            <div className='block-content bg-gd-cherry'>
                <textarea
                    className="form-control mb-4"
                    rows={15}
                    value={JSON.stringify(object, null, 2)}
                    disabled />
            </div>
        </div>
    )
}