import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { showRequestError } from '../../config/endpoints';
import { errorSwal } from '../../config/template';
import { createClientsRequest } from './Client.requests';
import { ClientListRoute } from './ClientList';
import { ClientViewRoute } from './ClientView';


export const ClientCreateRoute = "/clients/create"


interface Inputs {
    name :string;
    place? :string;
    contact? :string;
}

function ClientCreate() {

    const navigate = useNavigate();

    const { register, control, handleSubmit, watch, formState: { errors }, setValue, resetField } = useForm<Inputs>({mode: "all"})

    const watchForm = watch();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        const res = await createClientsRequest(data);
        if(res.response.ok && res.body.uuid){
            navigate(ClientViewRoute.replace(":uuid", res.body.uuid))
        }
        else{
            showRequestError(res);
        }
    };

    return (
        <>
            <h2 className="content-heading">{watchForm.name || "Criar Cliente"}</h2>
            <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>

                {/* info */}
                <div className='block block-rounded'>
                    <div className='block-header block-header-default'>
                        <h3 className="block-title">Informações</h3>
                    </div>

                    <div className='block-content'>
                        <div className="mb-4">
                            <label className="form-label" htmlFor="name">Nome</label>
                            <input type="text" className={`form-control ${errors.name && "is-invalid"}`}
                                placeholder="Nome do Cliente"
                                {...register("name", { required: "Campo Obrigatório"})} 
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
                        </div>

                        <div className="mb-4">
                            <label className="form-label" htmlFor="place">Morada</label>
                            <input type="text" className={`form-control ${errors.place && "is-invalid"}`}
                                placeholder="Morada do Cliente"
                                {...register("place", { required: false})} />
                            {errors.place && <div className="invalid-feedback">Morada Inválida</div>}
                        </div>

                        <div className="mb-4">
                            <label className="form-label" htmlFor="contact">Contacto</label>
                            <input type="text" className={`form-control ${errors.contact && "is-invalid"}`}
                                placeholder="Contacto do Cliente"
                                {...register("contact", { required: false })} />
                            {errors.contact && <div className="invalid-feedback">Contacto Inválido</div>}
                        </div>

                    </div>
                </div>



                {/* submit form */}
                <div className="mb-4 ">
                    <button type="submit" className='btn btn-primary'>
                        Submeter
                    </button>
                </div>
            </form>
        </>
    )
}

export default ClientCreate