import { ClientServerStatus, IClientServer } from "../config/shared"



interface IServerCard {
    server: IClientServer,
    onPress?: ()=>void
}

const ServerCard = ({ server, onPress = ()=> {}, }: IServerCard) => {
    // console.log(`${id} updated`);

    let classes = `fa fa-circle fa-2x `
    if (server.status === ClientServerStatus.connected) classes += " text-success opacity-75"
    else if (server.status === ClientServerStatus.warning) classes += " text-warning opacity-75"
    else if (server.status === ClientServerStatus.verifing) classes += " text-elegance opacity-75"
    else if (server.status === ClientServerStatus.unknown) classes += " text-danger opacity-75"
    else classes += " opacity-25"

    // const serverClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    //     onClick()
    // }

    return (

        <div className="col-12 col-sm-6 col-md-4 col-xl-3 user-select-none d-flex" role={"button"}>
            <a className="block block-rounded block-link-shadow text-end flex-fill" onClick={onPress}>
                <div className={`block-content block-content-full d-flex justify-content-between align-items-start`}>
                    <div className="d-sm-block d-flex flex-column pt-1">
                        <i className={classes}></i>
                    </div>

                    <div>
                        <div className="fs-3 fw-semibold text-break">{server.appname} - {server.clientname}</div>
                        <div className="fs-sm fw-semibold text-uppercase text-muted text-break">
                            {ClientServerStatus[server.status]}
                            {server.status === ClientServerStatus.unknown &&
                                <i className="fa fa-fw fa-exclamation-triangle text-danger"></i>
                            }
                        </div>
                    </div>
                    
                </div>
            </a>
        </div>

    )

}


export default ServerCard
