import Swal, { SweetAlertResult } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";



export const initCodebase =() => (window as any).Codebase.init();

export const sidebar_toggle = () => {
    (window as any).Codebase.layout('sidebar_toggle');
}

export const sidebar_open = () => {
    (window as any).Codebase.layout('sidebar_open');
}

export const sidebar_close = () => {
    (window as any).Codebase.layout('sidebar_close');
}

export const search_open = () => {
    (window as any).Codebase.layout('header_search_on')
}

export const search_close = () => {
    (window as any).Codebase.layout('header_search_off')
}



// swal
export const MySwal = withReactContent(Swal);

export const infoSwal = async (title?: string): Promise<SweetAlertResult<any>> => {
    return await MySwal.fire({
        icon: "info",
        title: title ?? "certeza ? ",
        denyButtonText: "Cancelar",
        showDenyButton: true,
    })
}

export const errorSwal = async (error?: string): Promise<SweetAlertResult<any>> => {
    return await MySwal.fire({
        icon: "error",
        title: "Ocorreram um ou mais erros",
        html: error ?? "",
    });
}


export const htmlSwal = async (html: JSX.Element, width? :number ): Promise<SweetAlertResult<any>> => {
    return await MySwal.fire({
        html: html,
        width: width ? width : undefined,
    });
}
