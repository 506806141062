import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Table from '../../components/Table'
import { iLicense, isLicenseValid } from '../../config/shared';
import { findAllLicensesRequest } from './License.requests';
import { LicenseCreateRoute } from './LicenseCreate';
import { LicenseViewRoute } from './LicenseView';


const tableHeader = [
  { name: "#", style: "" },
  { name: "Applicação", },
  { name: "Data Início", style: "text-center" },
  // { name: "Valida", style: "text-center" },
  { name: "Ações", style: "text-center" },
];



export const LicenseListRoute = "/licenses"

function LicenseList() {


  const [data, setData] = useState<iLicense[]>([])
  const [loading, setLoading] = useState(true)

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(data);


  const getLicenses = async() => {
    const { body, response} = await findAllLicensesRequest()
    response.ok ? setData(body) : setData([])
    console.log(body);
    
    setLoading(false)
  }

  useEffect(() => {
    getLicenses()
    return () => {}
  }, [])


  useEffect(() => {
    if (search.length !== 0) {
      setFilterData(
        data.filter((item) => {
          return item.uuid?.toString().toLowerCase().includes(search.toLowerCase()) ||
            item.application?.name?.toLowerCase().includes(search.toLowerCase())
        })
      );
    }
    else {
      setFilterData(data);
    }

  }, [search,data])


  const renderTableBodyData = () => {
    return filterData.map((license:iLicense) => {
        return (
            <tr key={license.uuid}>
                <td className="">{license.uuid}</td>
                <td>{license?.application?.name ?? ""}</td>
                <td className="text-center">{new Date(license.issueDate).toDateString()}</td>
                {/* <td className="text-center">
                  <span className={`badge ${isLicenseValid(license) ? "bg-success" : "bg-danger"}`}> &nbsp;</span>
                </td> */}


                <td className="text-center">
                    <Link to={LicenseViewRoute.replace(":uuid",license.uuid)} className="btn btn-sm btn-primary">
                        <i className="fa fa-eye"></i> Ver
                    </Link>
                    {/* &nbsp;
                    
                    <Link to={`#`} className="btn btn-sm btn-warning">
                        <i className="fa fa-edit"></i> Editar
                    </Link> */}
                    
                    {/* TODO */}
                    &nbsp;
                    {/* <button className="btn btn-sm btn-danger"
                        onClick={() => {}}>
                        <i className="fa fa-fw fa-trash"></i> Eliminar
                    </button> */}
                </td>
            </tr>
        )
    })
}

  return (
    <>
    
      <h2 className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center content-heading">
        Lista de Licenças
        <Link className='btn btn-primary' to={LicenseCreateRoute}>Criar</Link>
      </h2>

      <Table 
        header={tableHeader} 
        renderTableBodyData={renderTableBodyData}
        data={filterData}
        search={search}
        setSearch={setSearch}
        loading={loading}
      />
    
    </>
  )
}

export default LicenseList