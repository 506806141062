import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { searchRequest } from '../config/endpoints';
import { errorSwal, search_close } from '../config/template'
import { SearchPageRoute } from '../pages/System/SearchPage';

function HeaderSearch() {

    const [searchTerm, setSearchTerm] = useState<string>("");

    const navigate = useNavigate();


    const doRequest = async() => {
        if(searchTerm.trim() === "") return;

        const response = await searchRequest(searchTerm);

        if(!response.response.ok){
            const message = response.body.error ? `${response.body.error} - ${response.body.message}` : response.body.message
            errorSwal(message)
        }
        else{
            search_close();

            navigate(SearchPageRoute,{
                state: {
                    searchTerm, 
                    response: response.body
                }
            })
        }
        

    }

    const handleInput = (e :React.ChangeEvent<HTMLInputElement>) => {       
        setSearchTerm(e.target.value);
    }

    const keyDown = (e :React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter"){
            e.preventDefault();
            doRequest();
        }
        
    } 

    return (
        <div id="page-header-search" className="overlay-header bg-body-extra-light">
            <div className="content-header">
                <div className="input-group">
                    {/* <!-- Close Search Section --> */}
                    {/* <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() --> */}
                    <button type="button" className="btn btn-secondary" data-toggle="layout" data-action="header_search_off" onClick={search_close}>
                        <i className="fa fa-fw fa-times"></i>
                    </button>
                    {/* <!-- END Close Search Section --> */}
                    <input type="text" className="form-control" placeholder="Search or hit ESC.."
                        id="page-header-search-input" name="page-header-search-input" 
                        onChange={handleInput}
                        onKeyDown={keyDown}
                         />
                    <button type="button" className="btn btn-secondary" onClick={doRequest}>
                        <i className="fa fa-fw fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HeaderSearch