export interface iStatCard {
    num?: number|string;
    text?: string;
    icon?: string;
    background?: string;
    onClickFunction?(): void
}

const StatCard = ({
    num = 0,
    text = "",
    icon = "",
    background = "bg-muted",
    onClickFunction
}: iStatCard) => {

    return (
        <div className="col-6 col-sm-3 user-select-none" role={"button"}>
            <a className={`block block-rounded block-transparent ${background}`} onClick={onClickFunction} >
                <div className="block-content block-content-full block-sticky-options">
                    <div className="block-options">
                        <div className="block-options-item">
                            <i className={`fa text-white-75 ${icon}`}></i>
                        </div>
                    </div>
                    <div className="py-1 text-center">
                        <div className="fs-2 fw-bold mb-0 text-break text-white">{num}</div>
                        <div className="fs-sm fw-semibold text-break text-uppercase text-white-75">{text}</div>
                    </div>
                </div>
            </a>
        </div>
    )
}


export default StatCard;