import ApplicationCreate, { ApplicationCreateRoute } from "../pages/Applications/ApplicationCreate"
import ApplicationCreateVersion, { ApplicationCreateVersionRoute } from "../pages/Applications/ApplicationCreateVersion"
import ApplicationList, { ApplicationListRoute } from "../pages/Applications/ApplicationList"
import ApplicationView, { ApplicationViewRoute } from "../pages/Applications/ApplicationView"
import VersionView, { VersionViewRoute } from "../pages/Applications/VersionView"

import ClientCreate, { ClientCreateRoute } from "../pages/Clients/ClientCreate"
import ClientList, { ClientListRoute } from "../pages/Clients/ClientList"
import ClientView, { ClientViewRoute } from "../pages/Clients/ClientView"
import DashboardPage, { DashboardPageRoute } from "../pages/DashboardPage"
import DefaultPage from "../pages/DefaultPage"
import LicenseCreate, { LicenseCreateRoute } from "../pages/Licenses/LicenseCreate"
import LicenseList, { LicenseListRoute } from "../pages/Licenses/LicenseList"
import LicenseView, { LicenseViewRoute } from "../pages/Licenses/LicenseView"
import ProfilePage, { ProfilePageRoute } from "../pages/System/Profile"
import SearchPage, { SearchPageRoute } from "../pages/System/SearchPage"
import SettingsPage, { SettingsPageRoute } from "../pages/System/Settings"
import SignObjectPage, { SignObjectPageRoute } from "../pages/System/SignObjectPage"
import SystemLogsPage, { SystemLogsPageRoute } from "../pages/System/SystemLogsPage"

const routes :any[] = [
    {path: DashboardPageRoute,element: DashboardPage},
    {path: SearchPageRoute,element: SearchPage},
    
    // Applications Routes
    {path: ApplicationListRoute,element: ApplicationList},
    {path: ApplicationCreateRoute,element: ApplicationCreate},
    {path: ApplicationViewRoute,element: ApplicationView},

    // Versions Routes
    {path: ApplicationCreateVersionRoute,element: ApplicationCreateVersion},
    {path: VersionViewRoute,element: VersionView},


    // Clients Routes
    {path: ClientListRoute,element: ClientList},
    {path: ClientCreateRoute,element: ClientCreate},
    {path: ClientViewRoute,element: ClientView},

    //License
    {path: LicenseListRoute,element: LicenseList},
    {path: LicenseCreateRoute,element: LicenseCreate},
    {path: LicenseViewRoute,element: LicenseView},

    // SYSTEM
    {path: SystemLogsPageRoute, element: SystemLogsPage},
    {path: SignObjectPageRoute, element: SignObjectPage},
    {path: SettingsPageRoute, element: SettingsPage},
    {path: ProfilePageRoute, element: ProfilePage},


    {path : "/default", element: DefaultPage},
]


export default routes