import React, { useEffect, useState } from 'react'


interface iCopyButton{
    content: string,
    className? :string,
}

function CopyButton({content, className}:iCopyButton) {

    const [copied, setCopied] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout>()

    useEffect(() => {
        if(copied){
            setTimer(
                setTimeout(() => { setCopied(false) }, 2000)
            )
        }
        else{
            clearTimeout(timer)
        }
    
      return () => {
        clearTimeout(timer)
      }
    }, [copied])

    

    return (
        <button 
        className={
            (className || 'btn btn-sm btn-secondary') + 
            ` ${copied ? " bg-success border-success text-white disabled":""}`
        } 
        onClick={(e) => {
            navigator.clipboard.writeText(`${content}`)
            e.currentTarget.blur();
            setCopied(true)
        }}>
            <i className={ copied 
            ? "fa fa-check me-1"
            : "fa fa-clipboard opacity-50 me-1"
            }></i>&nbsp;
            Copy
        </button>
    )
}

export default CopyButton