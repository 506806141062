import React, { useContext } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import { sidebar_close } from '../config/template';
import { UserContext } from '../hooks/UserContext';
import { ApplicationListRoute } from '../pages/Applications/ApplicationList';
import { ClientListRoute } from '../pages/Clients/ClientList';
import { DashboardPageRoute } from '../pages/DashboardPage';
import { LicenseListRoute } from '../pages/Licenses/LicenseList';
import { ProfilePageRoute } from '../pages/System/Profile';
import { SignObjectPageRoute } from '../pages/System/SignObjectPage';
import { SystemLogsPageRoute } from '../pages/System/SystemLogsPage';




function Sidebar() {

  const { user } = useContext(UserContext)

  return (
    <nav id="sidebar">
      <div className="sidebar-content">

        {/* <!-- Side Header --> */}
        <div className="content-header justify-content-lg-center">
          {/* <!-- Logo --> */}
          <div>
            <span className="smini-visible fw-bold tracking-wide fs-lg">
              C<span className="text-primary">S</span>
            </span>
            <Link className="link-fx fw-bold tracking-wide mx-auto" to={"/"} >
              <span className="smini-hidden">
                <i className="fa fa-key text-primary"></i>
                &nbsp;
                <span className="fs-4 text-dual">Cert</span><span className="fs-4 text-primary">SYS</span>
              </span>
            </Link>
          </div>
          {/* <!-- END Logo --> */}

          {/* <!-- Options --> */}
          <div>
            {/* <!-- Close Sidebar, Visible only on mobile screens --> */}
            {/* <!-- Layout API, functionality initialized in Template._uiApiLayout() --> */}
            <button type="button" className="btn btn-sm btn-alt-danger d-lg-none" data-toggle="layout" data-action="sidebar_close" onClick={sidebar_close}>
              <i className="fa fa-fw fa-times"></i>
            </button>
            {/* <!-- END Close Sidebar --> */}
          </div>
          {/* <!-- END Options --> */}
        </div>
        {/* <!-- END Side Header --> */}


        {/* <!-- Sidebar Scrolling --> */}
        <div className="js-sidebar-scroll">

          {/* <!-- Side User --> */}
          <div className="content-side content-side-user px-0 py-0">
            {/* <!-- Visible only in mini mode --> */}
            <div className="smini-visible-block animated fadeIn px-2">
              <img className="img-avatar img-avatar32" src="assets/media/avatars/avatar15.jpg" alt="" />
            </div>
            {/* <!-- END Visible only in mini mode --> */}

            {/* <!-- Visible only in normal mode --> */}
            <div className="smini-hidden text-center mx-auto">
              {/* <a className="img-link" href="be_pages_generic_profile.html">
                <img className="img-avatar" src="/assets/media/avatars/avatar15.jpg" alt="" />
              </a>

              <ul className="list-inline mt-3 mb-0">
                <li className="list-inline-item">
                  <a className="link-fx text-dual fs-sm fw-semibold text-uppercase" href="be_pages_generic_profile.html">{user?.username ?? ""}</a>
                </li>
              </ul> */}

              <Link className="img-link" to={ProfilePageRoute}>
                <img className="img-avatar" src="/assets/media/avatars/avatar15.jpg" alt="" />
              </Link>


              <ul className="list-inline mt-3 mb-0">
                <li className="list-inline-item">
                  <Link className="link-fx text-dual fs-sm fw-semibold text-uppercase" to={ProfilePageRoute}> {user?.username ?? ""}</Link>
                </li>
              </ul>



            </div>
            {/* <!-- END Visible only in normal mode --> */}
          </div>
          {/* <!-- END Side User --> */}

          <SidebarNav />


        </div> {/* <!-- END Sidebar Scrolling --> */}


      </div>
      {/* <!-- Sidebar Content --> */}
    </nav>
  )
}

export default Sidebar


const SidebarNav = () => {
  return (
    <>
      {/* <!-- Side Navigation --> */}
      <div className="content-side content-side-full">
        <ul className="nav-main">

          <SidebarItem url={DashboardPageRoute} name="Dashboard" icon="fa-house" />

          <li className="nav-main-heading">Main</li>
          <SidebarItem url={ApplicationListRoute} name={'Aplicações'} icon={'fa-code'} />
          <SidebarItem url={ClientListRoute} name={'Clientes'} icon={'fa-building'} />
          <SidebarItem url={LicenseListRoute} name={'Licenças'} icon={'fa-lock'} />

          <li className="nav-main-heading">SYSTEM</li>
          <SidebarItem url={SystemLogsPageRoute} name="Logs" icon='fa-list-check' />
          <SidebarItem url={SignObjectPageRoute} name="Sign Objects" icon='fa-file-signature' />
          {/* <SidebarItem url={''} name="Users" icon='fa-user' /> */}


        </ul>
      </div>
      {/* <!-- END Side Navigation --> */}
    </>
  )
}


interface SidebarItemProps {
  url: string, name: string, icon: string
}
const SidebarItem = ({ url, name, icon }: SidebarItemProps) => {

  const isMobile = useMediaQuery({
    screen: true,
    maxWidth: 992
  })
  let mobileProps = {};
  if (isMobile) {
    mobileProps = { "data-toggle": "layout", "data-action": "sidebar_close" };

  }

  const location = useLocation();
  const isActive = location.pathname.startsWith(url.trim()) && url.trim() != ""
  // console.log(name, " -> ",url, " = ", isActive, `(${location.pathname})`);
  // console.log(location)


  return (
    <>
      <li className="nav-main-item">
        <Link
          className={`nav-main-link ${isActive ? "active" : ""}`}
          to={url}
          {...mobileProps}
          onClick={isMobile ? sidebar_close : undefined}
        >
          <i className={`nav-main-link-icon fa ${icon}`}></i>
          <span className="nav-main-link-name">{name}</span>
        </Link>
      </li>
    </>
  );
}