// TODO : Shared Lib ??

// regex
export const versionRegex = /^[0-9][0-9.]*$/;



export enum LogType {
    LOG = "LOG",
    INFO = "INFO",
    DEBUG = "DEBUG",
    WARNING = "WARNING",
    DANGER = "DANGER",
    ALERT = "ALERT",
}


export enum ClientServerStatus {
    disconnected = "disconnected",
    verifing = "verifing",
    warning = "warning",
    connected = "connected",
    unknown = "unknown"
}


export interface IClientServer {
    LicenseUuid: string,
    appname: string,
    clientname: string,
    status: ClientServerStatus,
    clientSocketInfo?: iClientServerInfo,

}


interface iClientServerInfo {
    remoteAddress: string,
    remotePort: number,
    remoteFamily: string,
    key: string
  }


export interface MessageFormat {
    event: string,
    data: any[],
}


export enum DashboardEvents {
    Message = "Message",
    GET_CLIENTS = "GET_CLIENTS",
    SEND_COMMAND = "SEND_COMMAND",
}

export enum WsEventList {
    message = "message",
    run = "run",

    VerifyClientApi = "VerifyClientApi", 

    GetHealthCheck = "GetHealthCheck",
    LicenseCheck = "LicenseCheck",
    EncryptionCheck = "EncryptionCheck",
    HashCheck = "HashCheck",
    GetPublicIp = "GetPublicIp",

    Exit = "Exit"
}




// ------------------------------------

export interface iBaseEntity {
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
    deletedBy: string | null;
}
export interface iBaseEntityId extends iBaseEntity {
    id: number;
}
export interface iBaseEntityUuid extends iBaseEntity {
    uuid: string;
}
export interface iBaseEntityUuidID extends iBaseEntity {
    uuid: string;
    id: number;
}


export interface iClient extends iBaseEntityUuidID {
    name: string;
    place?: string;
    contact?: string;

    licenses?: iLicense[];
}

export interface iLicense {
    uuid: string;
    issueDate: Date;
    expirationDate?: Date;
    url? :string;
    revokedMotive? :string


    application: iApplication;
    client: iClient;
    version?: iVersion;
    certificate: string;
    parameters?: object;
}

export interface iVersion extends iBaseEntityUuid {
    number: string;
    parameters: object;
    hash: IHash[];

    application: iApplication;
    licenses?: iLicense[];
}

export interface IHash extends iBaseEntityUuid{
    hashData: object;
    version: iVersion;
}

export interface iApplication extends iBaseEntityUuidID {
    name: string;
    applictionType: ApplicationType;
    applicationConnectionType: ApplicationConnectionType;


    licenses?: iLicense[];
    versions?: iVersion[]
}

export enum ApplicationType {
    NEST = "NEST",
    NODE = "NODE",
    PHP = "PHP",
}

export enum ApplicationConnectionType{
    WEBSOCKET="WEBSOCKET",
    HTTP="HTTP",
}

//functions need to be shared

export const hasLicenseExpired = (license :iLicense) :boolean => {
    if (license.expirationDate === null) return false;

    const today = new Date();
    const issueDate = new Date(license.issueDate);
    const expirationDate = new Date(license.expirationDate!)
    const validLicense = issueDate < expirationDate && today <= expirationDate


    // console.log(`validLicense=> ${validLicense}`);
    // console.log(`license.expirationDate == null => ${license.expirationDate == null}`);
    // console.log(`license.issueDate < license.expirationDate => ${license.issueDate < license.expirationDate}`);
    // console.log(`today <= license.expirationDate => ${today <= license.expirationDate}`);

    // console.log(`${today} <= ${license.expirationDate}`);



    return !validLicense;
}

export const isLicenseRevoked = (license :iLicense) :boolean => license.revokedMotive !== null

export const isLicenseValid = (license: iLicense): boolean => {
    if(isLicenseRevoked(license)) return false;
    if(hasLicenseExpired(license)) return false;
    return true
}



export interface iLicensePercentage {
    percentage: number,
    totalDays: number,
    passedDays: number
}

export const calculateLicensePercentage = (license: iLicense): iLicensePercentage => {

    const today = new Date();

    const issueDate = new Date(license.issueDate);

    const passedMs = today.valueOf() - issueDate.valueOf();
    const passedDays = passedMs / (1000 * 60 * 60 * 24);

    if (license.expirationDate === null) {
        return { percentage: 5, totalDays: 0, passedDays: Math.round(passedDays) }
    }

    const expirationDate = new Date(license.expirationDate!)





    let percentage = 0

    const totalMs = expirationDate.valueOf() - issueDate.valueOf()
    const totalDays = totalMs / (1000 * 60 * 60 * 24);


    percentage = (passedMs / totalMs) * 100
    // const percentageDays = (passedDays/totalDays)*100

    return {
        percentage: Math.floor(percentage),
        totalDays: Math.floor(totalDays),
        passedDays: Math.round(passedDays),
    };
}