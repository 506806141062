import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import BsModal, { useModalHook } from '../../components/BsModal'
import Table from '../../components/Table'
import { calculateLicensePercentage, DashboardEvents, IClientServer, iLicense, isLicenseValid, LogType, WsEventList } from '../../config/shared'
import { errorSwal, htmlSwal, infoSwal, MySwal } from '../../config/template'
import { UserContext } from '../../hooks/UserContext'
import { DebugObject } from '../../utils/Debug'
import { sleep } from '../../utils/Sleep'
import { ApplicationViewRoute } from '../Applications/ApplicationView'
import { VersionViewRoute } from '../Applications/VersionView'
import { ClientViewRoute } from '../Clients/ClientView'
import { generateLicenseFileRequest, revokeLicenseRequest, viewLicensesRequest } from './License.requests'
import { ISelectLabelValue } from './LicenseCreate'

import Select from 'react-select';
import { enumToOptions } from '../../utils/EnumUtils'
import { ConnectedClientsContext, useWebSocketProvider } from '../../hooks/WebSocketContext'
import { ReadyState } from 'react-use-websocket'
import ServerCard from '../../components/ServerCard'
import { getlicenseLogsRequest, showRequestError } from '../../config/endpoints'
import { ReactSelect } from '../../components/ReactSelect'
import CopyButton from '../../components/CopyButton'



export const LicenseViewRoute = "/license/view/:uuid"


function LicenseView() {

    // const {
    //     sendMessage,
    //     sendJsonMessage,
    //     lastMessage,
    //     lastJsonMessage,
    //     readyState,
    //     getWebSocket,
    // } = useWebSocketProvider()


    const { serverList, readyState } = useContext(ConnectedClientsContext)


    const { uuid } = useParams()
    const [license, setLicense] = useState<iLicense | undefined | null>(undefined);

    const [Modal, openModal, closeModal, setModalBody] = useModalHook({
        id: "logsModal",
        backdrop: "static",
        title: `Logs`,
        size: "modal-xl",
        fade: true,
        effect: "modal-dialog-popout",
    });

    const [LicenseModal, openLicenseModal, closeLicenseModal, setLicenseModalBody] = useModalHook({
        id: "licenseModal",
        backdrop: "static",
        title: "Licença",
        size: "modal-xl",
        fade: true,
        effect: "modal-dialog-popout",
    });


    const [revokeMotiveState, setRevokeMotiveState] = useState<string>("");
    const [RevokeModal, openRevokeModal, closeRevokeModal, setRevokeModalBody] = useModalHook({
        id: "RevokeModal",
        backdrop: "static",
        title: "Revogar Licença",
        size: "modal-xl",
        fade: true,
        effect: "modal-dialog-popout",
        bottom: <>
            <button className='btn btn-danger' onClick={finishRevokeLicense}>Eliminar</button>
        </>
    });

    async function finishRevokeLicense() {

        if (revokeMotiveState.trim() == "") return;

        const res = await revokeLicenseRequest({ id: uuid!, motive: revokeMotiveState })

        closeRevokeModal();
        setRevokeMotiveState("");

        if (res.response.ok && res.body.uuid) {
            setLicense(res.body);
        }
        else {
            showRequestError(res);
        }

    }

    const openRevokeLicense = async () => {
        setRevokeMotiveState("");
        setRevokeModalBody(
            <>
                <label className="form-label" >Motivo para Revogar a Licença?</label>
                <input className='form-control' type="text" onChange={(e) => setRevokeMotiveState(e.target.value)} />
            </>,
        )

        openRevokeModal();
    }





    const getLicense = async () => {
        const {body,response} = await viewLicensesRequest(uuid ?? "");
        response.ok ? setLicense(body) : setLicense(null) 
    }

    useEffect(() => {
        getLicense()
        return () => { setLicense(undefined) }
    }, [])


    useEffect(() => {
        return () => { }
    }, [license])


    //RENDER

    if (license == null) {
        return <> <h1>LICENSE NOT FOUND</h1> </>
    }





    const validLicense = isLicenseValid(license);
    const LicenseProgress = calculateLicensePercentage(license)
    const LicenseProgressColor: string = LicenseProgress.percentage >= 95
        ? "bg-danger" : LicenseProgress.percentage >= 75 ? "bg-warning" : "bg-success"



    const openLicense = async () => {
        const res = await generateLicenseFileRequest(license.uuid)

        if (!res.response.ok) {
            showRequestError(res);
        }
        else {
            const jsonString = JSON.stringify(res.body, null, 2)
            setLicenseModalBody(
                <>
                    <h3>{license.uuid}</h3>

                    <textarea
                        className="form-control my-2"
                        rows={15}
                        value={jsonString}
                        disabled />

                    <CopyButton 
                        content={jsonString}
                        className="btn btn-alt-secondary btn-sm"
                    />

                </>
            )
            openLicenseModal();

        }

    }


    const openModalLogs = async () => {
        setModalBody(
            <LogTable
                id={uuid!}
            />
        )
        openModal();
    }



    return (
        <div>
            {/* info */}

            <div className='row'>
                <div className="col-md-6 col-xl-3">
                    <Link className="block block-rounded block-link-shadow" to={ClientViewRoute.replace(":uuid", license.client.uuid)}>
                        <div className="block-content block-content-full block-sticky-options">
                            <div className="py-3 text-center">
                                <div className="fs-2 fw-bold mb-0 text-break text-info">{license.client.name}</div>
                                <div className="fs-sm fw-semibold text-break text-uppercase text-muted">Cliente</div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6 col-xl-3">
                    <Link className="block block-rounded block-link-shadow" to={ApplicationViewRoute.replace(":uuid", license.application?.uuid)}>
                        <div className="block-content block-content-full block-sticky-options">
                            <div className="py-3 text-center">
                                <div className="fs-2 fw-bold mb-0 text-break text-warning">{license.application?.name}</div>
                                <div className="fs-sm fw-semibold text-break text-uppercase text-muted">Applicação</div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6 col-xl-3">
                    <Link className="block block-rounded block-link-shadow" to={VersionViewRoute.replace(":uuid", license.application?.uuid).replace(":versionUuid", license.version!.uuid)}>
                        <div className="block-content block-content-full block-sticky-options">
                            <div className="py-3 text-center">
                                <div className="fs-2 fw-bold mb-0 text-break text-elegance">{license.version?.number}</div>
                                <div className="fs-sm fw-semibold text-break text-uppercase text-muted">Versão</div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-md-6 col-xl-3">
                    <a className="block block-rounded block-link-shadow" href="#">
                        <div className="block-content block-content-full block-sticky-options">
                            <div className="py-3 text-center">
                                <div className={`fs-2 fw-bold mb-0 text-break ${validLicense ? "text-success" : "text-danger"}`}>{validLicense ? "Válida" : "Inválida"}</div>
                                <div className="fs-sm fw-semibold text-break text-uppercase text-muted">validação</div>
                            </div>
                        </div>
                    </a>
                </div>

            </div>



            {/* Detalhes */}
            <div className="content-heading d-flex justify-content-between align-items-center">
                <span>
                    Detalhes
                </span>
            </div>

            <div className="row items-push">
                {/* license */}
                <div className="col-md-4">
                    <div className="block block-rounded h-100 mb-0">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">
                                <i className="fa fa-file fa-fw me-1 text-muted"></i> Licença
                            </h3>
                        </div>
                        <div className="block-content block-content-full">
                            <p className="mb-1">
                                <strong>ID:</strong> {license.uuid}
                            </p>
                            <p className="mb-1">
                                <strong>Cliente:</strong> {license.client?.name}
                            </p>
                            <p className="mb-1">
                                <strong>Applicação:</strong> {license.application?.name}
                            </p>
                            <p className="mb-1">
                                <strong>Versão:</strong> {license.version?.number}
                            </p>

                            {license.url &&
                                <p className="mb-1">
                                    <strong>URL: </strong>
                                    <a href={license.url} target="_blank">
                                        {license.url}
                                    </a>
                                </p>
                            }

                            {license.revokedMotive &&
                                <p className="mb-1 text-danger">
                                    <strong>Inválida:</strong> {license.revokedMotive}
                                </p>
                            }

                        </div>
                    </div>
                </div>

                {/* Dates */}
                <div className="col-md-4">
                    <div className="block block-rounded h-100 mb-0">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">
                                <i className="fa fa-calendar fa-fw me-1 text-muted"></i> Datas
                            </h3>
                        </div>
                        <div className="block-content block-content-full">
                            <p className="mb-1">
                                <strong>Data Início:</strong> {new Date(license.issueDate).toDateString()}
                            </p>
                            <p className="mb-1">
                                <strong>Data Fim:</strong> {license.expirationDate != null ? new Date(license.expirationDate).toDateString() : String.fromCharCode(8734)}
                            </p>
                            <p className="mb-1">
                                <strong>Progresso:</strong>
                            </p>
                            <div className="progress mb-1" style={{ height: 16 }}>
                                <div className={`progress-bar progress-bar-striped progress-bar-animated ${LicenseProgressColor}`}
                                    role="progressbar"
                                    style={{ width: `${LicenseProgress.percentage == 0 ? 1 : LicenseProgress.percentage}%` }}
                                    aria-valuenow={LicenseProgress.percentage} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                            <small className='text-muted'>{LicenseProgress.passedDays}/{LicenseProgress.totalDays || String.fromCharCode(8734)} dias</small>
                        </div>
                    </div>
                </div>

                {/* Actions */}
                <div className="col-md-4">
                    <div className="block block-rounded h-100 mb-0">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">
                                <i className="fa fa-cog fa-fw me-1 text-muted"></i> Ações
                            </h3>
                        </div>
                        <div className="block-content block-content-full">
                            <p>
                                <button className='btn btn-alt-primary w-100'
                                    onClick={openLicense}>
                                    Ver Licença
                                </button>
                            </p>
                            {validLicense &&
                                <p>
                                    <button className='btn btn-alt-danger w-100'
                                        onClick={openRevokeLicense}>
                                        Revogar Licença
                                    </button>
                                </p>
                            }

                            <p>
                                <button className='btn btn-info w-100'
                                    onClick={openModalLogs}>
                                    Abrir Logs
                                </button>
                            </p>


                        </div>



                    </div>
                </div>
            </div>





            {/* servers */}

            <div className="content-heading d-flex justify-content-between align-items-center">
                <span>
                    Servers <small className="d-none d-sm-inline"></small>
                </span>
            </div>


            {readyState == ReadyState.OPEN &&
                <div className='row'>
                    {serverList.length > 0 && serverList
                        .filter((server) => server.LicenseUuid === uuid)
                        .map((server: IClientServer, index: number) =>
                            <ServerCard
                                key={index}
                                server={server}
                            />
                        )

                    }
                </div>
            }

            {Modal}
            {LicenseModal}
            {RevokeModal}


            {DebugObject(license, "license")}

        </div>
    )
}

export default LicenseView











// TODO : Tornar num Componente Reutilizável

interface iLogTable {
    id: string
}

const LogTable = (props: iLogTable) => {

    const tableHeader = [
        { name: "#", style: "text-center" },
        { name: "Type", },
        { name: "Data", style: "text-center" },
        { name: "Mensagem", },
        { name: "raw", },
    ];

    const userContext = useContext(UserContext)
    const { darkMode, user, accessToken } = userContext
    const {sendJsonMessage} = useWebSocketProvider(userContext);



    const [small, setSmall] = useState(true);
    const invertSmall = () => setSmall(!small);



    const WsEventListOptions = enumToOptions(WsEventList);
    const commandRef = useRef<any>(null)

    const sendWsCommand = async () => {
        const commandToSend = commandRef.current.getValue()[0].value
        console.log(commandToSend);

        sendJsonMessage({
            event: DashboardEvents.SEND_COMMAND,
            data: {
                type: commandToSend,
                uuid: props.id,
                requestedBy: user!.username
            }
        });

        await sleep(500);

        fetchData()
    }



    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any[]>([])
    const [limit, setLimit] = useState<number>(50);



    const fetchData = async () => {
        console.log(`limit : `, limit);

        setLoading(true)
        await sleep(1000)
        const { response, body } = await getlicenseLogsRequest({
            id: props.id,
            limit,
        })
        
        response.ok ? setData(body) : setData([])

        setLoading(false)
    }

    useEffect(() => {
        fetchData();
        return () => {
            setData([]);
        }
    }, [limit])



    return (
        <>
            <p>
                <button className='btn btn-sm btn-primary mx-2' onClick={invertSmall}>
                    Small : {small ? "on" : "off"}
                </button>
                <button className='btn btn-sm btn-primary mx-2' onClick={fetchData}>
                    <i className="fa fa-refresh"></i> Refresh
                </button>

                {limit !== 0 &&
                    <button className='btn btn-sm btn-primary mx-2' onClick={() => setLimit(0)}>
                        <i className="fa fa-th-list"></i> Load All
                    </button>
                }
            </p>

            <div className='row align-items-center my-4'>
                {/* <Select
                    ref={commandRef}
                    options={WsEventListOptions}

                    className="col"
                /> */}
                <ReactSelect
                    selectRef={commandRef}
                    options={WsEventListOptions}

                    className="col"
                />
                <div className='col'>
                    <button className='btn btn-sm btn-primary mx-2' onClick={sendWsCommand}>
                        <i className="fa fa-paper-plane"></i> Enviar
                    </button>
                </div>
            </div>

            <Table
                header={tableHeader}
                renderTableBodyData={() =>
                    data.map(item => {
                        let badgeColor = "bg-success";
                        let rowColor = "table-success";

                        if (item["logType"] == LogType.INFO) {
                            badgeColor = "bg-info";
                            rowColor = "table-info";
                        }
                        else if (item["logType"] == LogType.DEBUG) {
                            // badgeColor = "bg-black-50";
                            // rowColor = "bg-black-25";

                            badgeColor = "bg-elegance";
                            rowColor = darkMode ? "bg-gd-elegance" : "bg-elegance-lighter";
                        }
                        else if (item["logType"] == LogType.WARNING) {
                            badgeColor = "bg-warning";
                            rowColor = "table-warning";
                        }
                        else if (item["logType"] == LogType.ALERT) {
                            badgeColor = "bg-warning";
                            rowColor = "table-warning";
                        }
                        else if (item["logType"] == LogType.DANGER) {
                            badgeColor = "bg-danger";
                            rowColor = "table-danger";
                        }

                        const emptyRawData = (Object.keys(item["rawData"]).length === 0);


                        return (
                            <Fragment key={item["id"]}>
                                <tr className={rowColor}>
                                    <td className="text-center fw-bold">{item["id"] ?? "?"}</td>
                                    <td className="">
                                        <span className={`badge ${badgeColor} w-100`}>{item["logType"] ?? ""}</span>
                                    </td>
                                    <td className="text-center">{new Date(item["date"]).toLocaleString()}</td>
                                    <td>{item["message"] ?? ""}</td>
                                    <td>
                                        {!emptyRawData &&
                                            <button className="btn btn-primary btn-sm" type="button"
                                                data-bs-toggle="collapse" data-bs-target={`#raw_${item["id"]}`}
                                                aria-expanded="false" aria-controls={`raw_${item["id"]}`}>
                                                <i className="fa fa-eye"></i> Ver
                                            </button>
                                        }

                                    </td>
                                </tr>

                                {!emptyRawData &&
                                    <tr className='collapse' id={`raw_${item["id"]}`}>
                                        <td className='text-break' colSpan={5}>
                                            <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(item["rawData"], null, 2) ?? ""}</pre>
                                        </td>
                                    </tr>
                                }

                            </Fragment>
                        )

                    })
                }
                data={data}
                loading={loading}
                small={small}
                striped={false}
            />

        </>

    )
}
