import { ClientEndpoint, makeRequest } from "../../config/endpoints";


export const findAllClientsRequest = async () => 
    await makeRequest("get", ClientEndpoint);

export const createClientsRequest = async (data: any) => 
    await makeRequest("post",ClientEndpoint,data);


export const viewClientsRequest = async (id:string) => 
    await makeRequest("get", `${ClientEndpoint}/${id}`);


// TODO : 
export const deleteClientsRequest = async (id:string) => {
    const response = await fetch(`${ClientEndpoint}/${id}`, {
        method: "DELETE",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    .then(res => res.json())
    .catch(err => {console.error(err); throw err});
    return response
}