import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CopyButton from '../../components/CopyButton';
import ServerCard from '../../components/ServerCard';
import { showRequestError } from '../../config/endpoints';
import { ApplicationConnectionType, ApplicationType, IClientServer, IHash, iVersion } from '../../config/shared';
import { errorSwal, htmlSwal } from '../../config/template';
import { DebugObject } from '../../utils/Debug';
import { createVersionHashRequest, deleteVersionHashRequest, generateHashFileRequest, viewVersionRequest } from './Application.requests';

// export const VersionViewRoute = "/version/view/:uuid"
export const VersionViewRoute = "/applications/view/:uuid/version/:versionUuid"



function VersionView() {

    const { uuid, versionUuid } = useParams()
    const [version, setVersion] = useState<iVersion | undefined | null>(undefined)


    const getVersion = async () => {
        const { body, response } = await viewVersionRequest(versionUuid ?? "");
        response.ok ? setVersion(body) : setVersion(null)
    }

    useEffect(() => {
        getVersion()
        return () => { setVersion(undefined) }
    }, [])



    const addHash = async () => {
        let input = "";
        const { value } = await htmlSwal(
            <>
                <h2>Novo Hash</h2>

                <textarea
                    className="form-control"
                    style={{ resize: "vertical" }}
                    rows={12}
                    onChange={(event) => input = event.target.value}
                />
            </>
            , 800
        )


        if (value && input) {
            console.log(input);
            try {
                const json = JSON.parse(input);
                const { body, response } = await createVersionHashRequest(versionUuid!, json);

                if (response.ok && body.uuid) {
                    setVersion(body);
                }
                else {
                    showRequestError({ body, response })
                }

            } catch (error) {
                if (error instanceof SyntaxError)
                    htmlSwal(<h1>Formato Inválido</h1>)
            }
        }
        input = "";
    }


    const removeHash = async (hashId: string) => {
        const res = await deleteVersionHashRequest(uuid!, hashId)
        console.log(res.body);

        if (res.response.ok) {
            getVersion();
        }
        else {
            showRequestError(res);
        }

    }

    const viewHashDetails = (hash: IHash) => {
        htmlSwal(
            <>
                <h1>{hash.uuid}</h1>

                <p className="mb-1 text-break"><strong>Data Criação: </strong>{new Date(hash.createdAt).toDateString()}</p>
                <p className="mb-1 text-break"><strong>Criado Por: </strong>{hash.createdBy}</p>
                <hr />

                {Object.entries(hash.hashData).map(([key, value], index) =>
                    <p key={index} className="mb-1 text-break">
                        <strong>{key}:</strong> {value}
                    </p>
                )}
            </>
            , 1000
        )
    }



    const openHashFile = async (hash: IHash) => {
        // TODO : make MODAL ??
        const res = await generateHashFileRequest(version!.uuid, hash.uuid)

        if (!res.response.ok) {
            showRequestError(res);
        }
        else {
            const jsonString = JSON.stringify(res.body, null, 2)
            htmlSwal(
                <>
                    <h3>{hash.uuid}</h3>

                    <textarea
                        className="form-control my-2"
                        rows={15}
                        value={jsonString}
                        disabled />

                    <CopyButton
                     content={jsonString}
                     className='btn btn-alt-secondary btn-sm'
                    />

                </>
                , 800
            )
        }
    }





    // RENDERS

    if (version == null) {
        return <> <h1>APP NOT FOUND</h1> </>
    }

    return (
        <div>

            {/* info */}
            <div className="my-3 text-center">
                <h2 className="fw-bold mb-2">
                    {version.number}
                </h2>
                <h3 className="h5 text-muted mb-0">
                    {version.application.name}
                </h3>
            </div>





            <div className="content-heading d-flex justify-content-between align-items-center">
                <span> Hashes <small>({version?.hash?.length})</small></span>
                <span>
                    <button className="btn btn-sm btn-primary" onClick={addHash}>
                        <i className="fa fa-fw fa-plus"></i> Novo
                    </button>
                </span>
            </div>

            {/* TODO :  UNIFORMIZAR BACKEND PARA PODER UNIFORMIZAR AQUI */}

            {version.application.applictionType === ApplicationType.PHP &&
                <div className="row items-push">
                    {version?.hash?.map((obj: IHash, index) =>
                        <div key={index} className="col-xl-4 col-lg-6">
                            <div className="block block-rounded h-100 mb-0">
                                <div className="block-header block-header-default">

                                    <h3 className="block-title">
                                        <i className="fa fa-calculator fa-fw me-1 text-muted"></i> Hash
                                    </h3>


                                    <div className="block-options">
                                        {obj.deletedAt == null &&
                                            <>
                                                <button className='btn btn-sm btn-info mx-1' onClick={() => viewHashDetails(obj)}>
                                                    Detalhes
                                                </button>
                                                <button className='btn btn-sm btn-danger mx-1' onClick={() => removeHash(obj.uuid)}>
                                                    Remover
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="block-content block-content-full">
                                    {Object.entries(obj.hashData).map(([key, value], index) =>
                                        <p key={index} className="mb-1 text-truncate">
                                            <strong>{key}:</strong> {value}
                                        </p>
                                    )}

                                    <button className='btn btn-alt-primary w-100'
                                        onClick={() => openHashFile(obj)}>
                                        Ver Ficheiro Hash
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }

            {version.application.applictionType === ApplicationType.NEST &&
                <div className="row items-push">
                    {version?.hash?.map((obj: IHash, index) =>
                        <div key={index} className="col-xl-4 col-lg-6">
                            <div className="block block-rounded h-100 mb-0">
                                <div className="block-header block-header-default">

                                    <h3 className="block-title">
                                        <i className="fa fa-calculator fa-fw me-1 text-muted"></i> Hash
                                    </h3>


                                    <div className="block-options">
                                        {obj.deletedAt == null &&
                                            <>
                                                <button className='btn btn-sm btn-info mx-1' onClick={() => viewHashDetails(obj)}>
                                                    Detalhes
                                                </button>
                                                <button className='btn btn-sm btn-danger mx-1' onClick={() => removeHash(obj.uuid)}>
                                                    Remover
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="block-content block-content-full">
                                    {/* {Object.entries(obj.hashData).map(([key, value], index) =>
                                        <p key={index} className="mb-1 text-truncate">
                                            <strong>{key}:</strong> {value}
                                        </p>
                                    )} */}
                                    {Object.entries((obj.hashData as any)["hashes"] as object).map(([key, value], index) =>
                                        <p key={index} className="mb-1 text-truncate">
                                            <strong>{key}:</strong> {value}
                                        </p>
                                    )}

                                    <button className='btn btn-alt-primary w-100'
                                        onClick={() => openHashFile(obj)}>
                                        Ver Ficheiro Hash
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }



            {DebugObject(version, "version")}

        </div>
    )
}

export default VersionView
