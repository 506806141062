export function dateIsValid(date: Date|string) : boolean{
    if(typeof date === "string"){
        date = inputDateToDate(date);
    }
    return date instanceof Date && !isNaN(date.valueOf());
}

export function inputDateToDate (date :string) :Date{
    return new Date(Date.parse(date))
}
