import { useState, createContext, useContext, ReactChild, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { socketUrl } from "../config/endpoints";
import { DashboardEvents, IClientServer, MessageFormat } from "../config/shared";
import { UserContext, userContextProviderValues } from "./UserContext";





export const useWebSocketProvider = (userContext :userContextProviderValues) => useWebSocket(socketUrl, {
  queryParams: { accessToken: userContext.accessToken ?? "" },
  onOpen: () => console.log('opened'),
  shouldReconnect: (closeEvent :CloseEvent) => {
    if(closeEvent.code === 4003 || closeEvent.reason == "FORBIDDEN"){
      userContext.logout()
      return false
    }
    return true
  }, 

  reconnectAttempts: userContext.accessToken ? Number.MAX_SAFE_INTEGER : 0,
  share: true,
});


export const WebSocketConnectionStatus = (readyState: ReadyState = ReadyState.CLOSED) => {
  switch (readyState) {
    case ReadyState.CONNECTING: return 'Connecting';
    case ReadyState.OPEN: return 'Open';
    case ReadyState.CLOSING: return 'Closing';
    case ReadyState.CLOSED: return 'Closed';
    case ReadyState.UNINSTANTIATED: return 'Uninstantiated';
    default: return "????";
  }
}




interface providerValues {
  serverList: IClientServer[],
  readyState: ReadyState,

}

export const ConnectedClientsContext = createContext<providerValues>({
  serverList: [],
  readyState: ReadyState.CLOSED,
});




export const DashboardSocketProvider = (props: any) => {

  const userContext = useContext(UserContext);

  const [serverList, setServerList] = useState<IClientServer[]>([])

  const { sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
  } = useWebSocketProvider(userContext)


  useEffect(() => {

    if (lastJsonMessage != null) {
      const message = (lastJsonMessage as unknown as MessageFormat)

      switch (message.event) {
        case DashboardEvents.GET_CLIENTS:
          setServerList(message.data)
          break;



        default:
          console.log(lastJsonMessage);
          break;
      }
    }

    return () => { }
  }, [lastMessage, lastJsonMessage])


  const getClients = () => {
    sendJsonMessage({ event: DashboardEvents.GET_CLIENTS, })
  }

  useEffect(() => {
    if (readyState == ReadyState.OPEN) getClients()

    if (readyState == ReadyState.CLOSED) setServerList([])

    return () => { }
  }, [readyState])



  return (
    <ConnectedClientsContext.Provider value={{ serverList, readyState }}>
      {props.children}
    </ConnectedClientsContext.Provider>
  )
}