import { ISelectLabelValue } from "../pages/Licenses/LicenseCreate";

export const enumToOptions = ( e :any, useLabelAsValue = true) :ISelectLabelValue[] => {
    if(useLabelAsValue){
        return Object.keys(e).map( (v,index) => {
            return {label : v, value: v}
        });
    }
    else{
        return Object.keys(e).map( (v,index) => {
            return {label : e[v], value: v}
        });
    }
}