import React, { useContext } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form';
import Select, { GroupBase, StylesConfig, ThemeConfig } from 'react-select';
import { UserContext } from '../hooks/UserContext';





// TODO : pode ser reutilizavel
type Options = {
    value: undefined | string;
    label: string;
}

interface iControlledProps {
    name: string,
    form: UseFormReturn<any, any>,
    options: Options[],
}

function ReactSelectControlled({
    name,
    form,
    options
}: iControlledProps) {

    const { darkMode } = useContext(UserContext)

    const watchForm = form.watch();


    return (
        <Controller
            name={name}
            control={form.control}
            rules={{ required: "Campo Obrigatório" }}
            render={(input) =>
                <Select
                    ref={input.field.ref}
                    onChange={val => input.field.onChange(val)}
                    value={watchForm[name] || null}
                    options={options}
                    styles={darkMode ? DARKMODE_STYLE : undefined}
                    // TODO : não fica com border de erro
                    className={`${input.fieldState.error && "is-invalid"}`}
                />
            }
        />
    )
}

export default ReactSelectControlled





// usable wrapper, but not complete.

interface iProps {
    options: Options[],

    selectRef?: any,
    onChange? :Function,
    className: string|undefined,
}

export function ReactSelect({
    options,

    selectRef,
    className
}: iProps) {

    const { darkMode } = useContext(UserContext)

    return (
        <Select
            ref={selectRef}
            options={options}
            styles={darkMode ? DARKMODE_STYLE : undefined}
            className={className}
        />
    )

}


// styles for darkmode

const DARKMODE_STYLE :StylesConfig<any, false, GroupBase<any>> | undefined = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        ...{
            borderColor: "#383f45",
            color: "#c5cdd8",
            backgroundColor: "#1b1f22",
        }
    }),

    singleValue: (baseStyles, state) => ({
        ...baseStyles,
        ...{
            borderColor: "#383f45",
            color: "#c5cdd8",
            backgroundColor: "#1b1f22",
        }
    }),

    menu: (baseStyles, state) => ({
        ...baseStyles,
        ...{
            backgroundColor: "#1b1f22",
        }
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        ...{
            // backgroundColor: "#1b1f22",
            backgroundColor: state.isDisabled
                ? undefined
                : state.isSelected
                    ? "#0284c7"
                    : state.isFocused ? "#383f45" : undefined
        }
    })

}