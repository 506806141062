import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Table from '../../components/Table'
import { iClient } from '../../config/shared';
import { infoSwal } from '../../config/template';
import { findAllClientsRequest } from './Client.requests';
import { ClientCreateRoute } from './ClientCreate';
import { ClientViewRoute } from './ClientView';


const tableHeader = [
  { name: "#", style: "text-center" },
  { name: "Nome", },
  { name: "Criado", style: "text-center" },
  { name: "Ações", style: "text-center" },
];





export const ClientListRoute = "/clients"

function ClientList() {


  const [data, setData] = useState<iClient[]>([])
  const [loading, setLoading] = useState(true)

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(data);

  const getClients = async() => {
    const { body, response } = await findAllClientsRequest()
    response.ok ? setData(body) : setData([]) 
    setLoading(false)
  }

  useEffect(() => {
    getClients()
    return () => {}
  }, [])


  useEffect(() => {
    if (search.length !== 0) {
      setFilterData(
        data.filter((item) => {
          return item.id?.toString().toLowerCase().includes(search.toLowerCase()) ||
            item.name?.toLowerCase().includes(search.toLowerCase())
        })
      );
    }
    else {
      setFilterData(data);
    }

  }, [search,data])


  const removeClient = (client :iClient) => {
    // TODO : 
    // + soft delete others
    infoSwal("TODO")

  }

  const renderTableBodyData = () => {
    return filterData.map((client:iClient) => {
        return (
            <tr key={client.uuid}>
                <td className="text-center">{client.id}</td>
                <td>{client.name ?? ""}</td>
                <td className="text-center">{new Date(client?.createdAt).toDateString()}</td>
                <td className="text-center">
                    <Link to={ClientViewRoute.replace(":uuid",client.uuid)} className="btn btn-sm btn-primary">
                        <i className="fa fa-eye"></i> Ver
                    </Link>

                    {/* &nbsp;
                    
                    <Link to={`#`} className="btn btn-sm btn-warning">
                        <i className="fa fa-edit"></i> Editar
                    </Link> */}
                    
                    &nbsp;
                    <button className="btn btn-sm btn-danger"
                        onClick={() => removeClient(client)}>
                        <i className="fa fa-fw fa-trash"></i> Eliminar
                    </button>
                </td>
            </tr>
        )
    })
}

  
  return (
    <>

      <h2 className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center content-heading">
        Lista de Clientes
        <Link className='btn btn-primary' to={ClientCreateRoute}>Criar</Link>
      </h2>

      <Table 
        header={tableHeader} 
        renderTableBodyData={renderTableBodyData}
        data={filterData}
        search={search}
        setSearch={setSearch}
        loading={loading}
      />
    
    </>
  )
}

export default ClientList