import { LicenseEndpoint, makeRequest } from "../../config/endpoints";


export const findAllLicensesRequest = async () => 
    await makeRequest("get", LicenseEndpoint);



export const createLicensesRequest = async (data: any) => 
    await makeRequest("post", LicenseEndpoint, data);


export const viewLicensesRequest = async (id:string) => 
    await makeRequest("get", `${LicenseEndpoint}/${id}`);


// TODO :
// Unused ???
export const deleteLicensesRequest = async (id:string) => {
    const response = await fetch(`${LicenseEndpoint}/${id}`, {
        method: "DELETE",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    .then(res => res.json())
    .catch(err => {console.error(err); throw err});
    return response
}

export const revokeLicenseRequest = async ({id, motive} :{ id:string,  motive :string }) =>
    await makeRequest("post", `${LicenseEndpoint}/${id}/revoke`, {motive});

export const generateLicenseFileRequest = async (id:string) =>
    await makeRequest("get", `${LicenseEndpoint}/${id}/generateLicenseFile`);
