import React, { useContext } from 'react'
import { UserContext } from '../hooks/UserContext'

function SideOverlay() {

  const {user} = useContext(UserContext)

  return (
    <>
        {/* <!-- Side Overlay--> */}
      <aside id="side-overlay">


        {/* <!-- Side Header --> */}
        <div className="content-header">
          {/* <!-- User Avatar --> */}
          <a className="img-link me-2" href="be_pages_generic_profile.html">
            <img className="img-avatar img-avatar32" src="assets/media/avatars/avatar15.jpg" alt="" />
          </a>
          {/* <!-- END User Avatar --> */}

          {/* <!-- User Info --> */}
          <a className="link-fx text-body-color-dark fw-semibold fs-sm" href="be_pages_generic_profile.html">
            {user?.username ?? ""}
          </a>
          {/* <!-- END User Info --> */}

          {/* <!-- Close Side Overlay --> */}
          {/* <!-- Layout API, functionality initialized in Template._uiApiLayout() --> */}
          <button type="button" className="btn btn-sm btn-alt-danger ms-auto" data-toggle="layout" data-action="side_overlay_close">
            <i className="fa fa-fw fa-times"></i>
          </button>
          {/* <!-- END Close Side Overlay --> */}
        </div>
        {/* <!-- END Side Header --> */}

        {/* <!-- Side Content --> */}
        <div className="content-side">




          {/* <!-- Mini Stats --> */}
          <div className="block pull-x">
            <div className="block-content block-content-full block-content-sm bg-body-light">
              <div className="row">
                <div className="col-4">
                  <div className="fs-sm fw-semibold text-uppercase text-muted">Stat</div>
                  <div className="fs-4">100</div>
                </div>
                <div className="col-4">
                  <div className="fs-sm fw-semibold text-uppercase text-muted">Stat</div>
                  <div className="fs-4">200</div>
                </div>
                <div className="col-4">
                  <div className="fs-sm fw-semibold text-uppercase text-muted">Stat</div>
                  <div className="fs-4">300</div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- END Mini Stats --> */}




          {/* <!-- Block --> */}
          <div className="block pull-x">
            <div className="block-header bg-body-light">
              <h3 className="block-title">Title</h3>
              <div className="block-options">
                <button type="button" className="btn-block-option" data-toggle="block-option" data-action="state_toggle" data-action-mode="demo">
                  <i className="si si-refresh"></i>
                </button>
                <button type="button" className="btn-block-option" data-toggle="block-option" data-action="content_toggle"></button>
              </div>
            </div>
            <div className="block-content">
              <p>Content...</p>
            </div>
          </div>
          {/* <!-- END Block --> */}


          
        </div>
        {/* <!-- END Side Content --> */}
      </aside>
      {/* <!-- END Side Overlay --> */}
    </>
  )
}

export default SideOverlay