import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReadyState } from 'react-use-websocket'
import { search_open, sidebar_toggle } from '../config/template'
import { UserContext } from '../hooks/UserContext'
import { ConnectedClientsContext } from '../hooks/WebSocketContext'
import { ProfilePageRoute } from '../pages/System/Profile'
import { SettingsPageRoute } from '../pages/System/Settings'
import HeaderSearch from './HeaderSearch'

function Header() {

  const { readyState } = useContext(ConnectedClientsContext)

  const { user, darkMode, darkmode_toggle, logout } = useContext(UserContext);





  const generateStatusBadge = () => {
    if (readyState === ReadyState.OPEN)
      return (<span className="badge bg-success">Connected</span>)

    if (readyState === ReadyState.CONNECTING)
      return (<span className="badge bg-warning">Connecting</span>)


    if (readyState === ReadyState.CLOSED) {
      console.log(readyState);
    }

    return (<span className="badge bg-danger">Closed</span>)
  }


  return (
    // <!-- Header -->
    <header id="page-header">

      {/* <!-- Header Content --> */}
      <div className="content-header">
        {/* <!-- Left Section --> */}
        <div className="space-x-2">
          {/* <!-- Toggle Sidebar --> */}
          {/* <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() --> */}
          <button type="button" className="btn btn-sm btn-alt-secondary" data-toggle="layout" data-action="sidebar_toggle" onClick={sidebar_toggle}>
            <i className="fa fa-fw fa-bars"></i>
          </button>
          {/* <!-- END Toggle Sidebar --> */}

          {/* <!-- Open Search Section --> */}
          {/* <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() --> */}
          <button type="button" className="btn btn-sm btn-alt-secondary" data-toggle="layout" data-action="header_search_on" onClick={search_open}>
            <i className="fa fa-fw fa-search"></i>
          </button>
          {/* <!-- END Open Search Section --> */}

          {generateStatusBadge()}

        </div>
        {/* <!-- END Left Section --> */}

        {/* <!-- Right Section --> */}
        <div className="space-x-1">
          {/* <!-- User Dropdown --> */}
          <div className="dropdown d-inline-block">
            <button type="button" className="btn btn-sm btn-alt-secondary" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fa fa-user d-sm-none"></i>
              <span className="d-none d-sm-inline-block fw-semibold">{user?.username ?? ""}</span>
              <i className="fa fa-angle-down opacity-50 ms-1"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-md dropdown-menu-end p-0" aria-labelledby="page-header-user-dropdown">
              <div className="px-2 py-3 bg-body-light rounded-top">
                <h5 className="h6 text-center mb-0">
                  {user?.username ?? ""}
                </h5>
              </div>
              <div className="p-2">

                <Link className="dropdown-item d-flex align-items-center justify-content-between space-x-1" to={ProfilePageRoute}>
                <span>Profile</span>
                  <i className="fa fa-fw fa-user opacity-25"></i>
                </Link>

                <div className="dropdown-divider"></div>

                {/* <!-- Toggle Side Overlay --> */}
                {/* <!-- Layout API, functionality initialized in Template._uiApiLayout() --> */}
                {/* <a className="dropdown-item d-flex align-items-center justify-content-between space-x-1" href="#" data-toggle="layout" data-action="side_overlay_toggle"> */}
                <Link className="dropdown-item d-flex align-items-center justify-content-between space-x-1" to={SettingsPageRoute}>
                  <span>Settings</span>
                  <i className="fa fa-fw fa-wrench opacity-25"></i>
                </Link>

                <a className="dropdown-item d-flex align-items-center justify-content-between space-x-1" href="#" onClick={() => darkmode_toggle()}>
                  <span>Dark Mode</span>
                  <i className={`fa fa-fw opacity-25 ${darkMode ? "fa-toggle-on" : "fa-toggle-off"}`}></i>
                </a>
                {/* <!-- END Side Overlay --> */}

                <div className="dropdown-divider"></div>
                <a className="dropdown-item d-flex align-items-center justify-content-between space-x-1" href="#" onClick={logout}>
                  <span>Sign Out</span>
                  <i className="fa fa-fw fa-sign-out-alt opacity-25"></i>
                </a>
              </div>
            </div>
          </div>
          {/* <!-- END User Dropdown --> */}


          {/* <!-- Toggle Side Overlay --> */}
          {/* <!-- Layout API, functionality initialized in Codebase() -> uiApiLayout() --> */}
          {/* <button type="button" className="btn btn-sm btn-alt-secondary" data-toggle="layout" data-action="side_overlay_toggle">
            <i className="fa fa-fw fa-stream"></i>
          </button> */}
          {/* <!-- END Toggle Side Overlay --> */}



        </div>
        {/* <!-- END Right Section --> */}
      </div>
      {/* <!-- END Header Content --> */}



      {/* <!-- Header Search --> */}
      <HeaderSearch />
      {/* <!-- END Header Search --> */}



      {/* <!-- Header Loader --> */}
      <div id="page-header-loader" className="overlay-header bg-primary">
        <div className="content-header">
          <div className="w-100 text-center">
            <i className="far fa-sun fa-spin text-white"></i>
          </div>
        </div>
      </div>
      {/* <!-- END Header Loader --> */}

      {/* <!-- END Header --> */}
    </header>
  )
}

export default Header