import { errorSwal } from "./template";

//export const socketUrl = `ws://@localhost:30001`;
export const socketUrl = `wss://api.certsys.emptytroubles.pt/front`;

//const apiUrl = `http://localhost:4444`;
const apiUrl = `https://api.certsys.emptytroubles.pt`;

export const ApplicationEndpoint = `${apiUrl}/application`;
export const ClientEndpoint = `${apiUrl}/client`;
export const LicenseEndpoint = `${apiUrl}/license`;
export const VersionEndpoint = `${apiUrl}/version`;

export const AuthEndpoint = `${apiUrl}/auth/login`;
export const SearchEndpoint = `${apiUrl}/search`;
export const LoggingEndpoint = `${apiUrl}/logging`;

export const SystemEndpoint = `${apiUrl}/system`;


interface HTTP_RESPONSE {
    response :Response,
    body :any,
    timer? :number // used in makeRequestInfo
}
type HTTP_METHODS = "get" | "delete" | "head" | "post" | "put" | "patch"

export const makeRequest = async (method: HTTP_METHODS, endpoint: string, data: any = null) :Promise<HTTP_RESPONSE> => {
    const response :Response = await fetch(endpoint,{
        method,
        mode: 'cors',
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.access_token ?? null}`
        }, 
        ...(data && {body: JSON.stringify(data)})
    })
    .catch(err => {console.error(err); throw err});

    const body = await response.json();
    

    return {
        response,
        body,
        timer: 0
    };
}

export const showRequestError =async (request:HTTP_RESPONSE) => {
    console.error(request)
    const {body,response} = request;
    const message = body.error ? `${body.error} - ${body.message}` : body.message
    errorSwal(message)
}



export const searchRequest = async (search:string) =>  await makeRequest("get",`${SearchEndpoint}?search=${search}`);

export const getSysLogsRequest = async (limit:number) => await makeRequest("get",`${LoggingEndpoint}/getSysLogs?limit=${""+limit}`)

export const getlicenseLogsRequest = async ({id,limit} :{ id:string; limit? :number; }) => 
    await makeRequest("get",`${LoggingEndpoint}/getLicenseLogs/${id}?limit=${""+limit}`)



export const doUserLogin = async (data: {username:string, password:string}) => 
    await makeRequest("post",AuthEndpoint,data);

export const changeUserPassword = async (data: any) => 
    await makeRequest("post",`${apiUrl}/auth/changePassword`,data);

export const getSysSignObject = async (data: {jsonstring :string}) => 
    await makeRequest("post",`${SystemEndpoint}/signobject`,data)



// helper function to calculate request time
export const makeRequestInfo = async (method: HTTP_METHODS, endpoint: string, data: any = null) :Promise<HTTP_RESPONSE> => {
    const t1 = performance.now();
    const result = await makeRequest(method,endpoint,data);
    const t2 = performance.now();
    return {
        ...result,
        timer: (t2-t1)
    }
}


export const getDeveloperLicenseRequest = async () =>
    await makeRequest("get", `${apiUrl}/auth/getDeveloperLicense` )

export const updateDeveloperLicenseRequest = async () =>
    await makeRequest("get", `${apiUrl}/auth/updateDeveloperLicense` )
