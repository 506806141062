import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import ServerCard from '../components/ServerCard';
import StatCard from '../components/StateCard';
import { ClientServerStatus, DashboardEvents, IClientServer, MessageFormat } from '../config/shared';
import { ConnectedClientsContext, useWebSocketProvider, WebSocketConnectionStatus, } from '../hooks/WebSocketContext';
import { LicenseViewRoute } from './Licenses/LicenseView';




export const DashboardPageRoute = "/dashboard"


function DashboardPage() {

  // const [serverList, setServerList] = useState<IClientServer[]>([])
  const [serversFilter, setServersFilter] = useState<undefined | ClientServerStatus>(undefined)
  const navigate = useNavigate();


  // const {
  //   sendMessage,
  //   sendJsonMessage,
  //   lastMessage,
  //   lastJsonMessage,
  //   readyState,
  //   getWebSocket,
  // } = useWebSocketProvider()
  

  const {serverList, readyState} = useContext(ConnectedClientsContext)

  const onServerClick = (server: IClientServer) => {
    const url = LicenseViewRoute.replace(":uuid", server.LicenseUuid);
    navigate(url)
  }

  // useEffect(() => {

  //   if (lastJsonMessage != null) {
  //     const message = (lastJsonMessage as unknown as MessageFormat)

  //     switch (message.event) {
  //       case DashboardEvents.GET_CLIENTS:
  //         setServerList(message.data)
  //         break;



  //       default:
  //         console.log(lastJsonMessage);
  //         break;
  //     }
  //   }

  //   return () => { }
  // }, [lastMessage, lastJsonMessage])



  // const getClients = () => {
  //   sendJsonMessage({ event: "GET_CLIENTS", })
  // }

  // useEffect(() => {
  //   if (readyState == ReadyState.OPEN) getClients()

  //   if (readyState == ReadyState.CLOSED) setServerList([])

  //   return () => { }
  // }, [readyState])



  return (
    <>
      <div className="row">
        <StatCard num={serverList.length} text={'All'} icon={'fa-archive'} background={'bg-info'}
          onClickFunction={() => setServersFilter(undefined)} />

        <StatCard num={serverList.filter(s => s.status == ClientServerStatus.connected).length}
          text={'Online'} icon={'fa-check'} background={'bg-success'}
          onClickFunction={() => setServersFilter(ClientServerStatus.connected)} />

        <StatCard num={serverList.filter(s => s.status == ClientServerStatus.warning).length}
          text={'Warning'} icon={'fa-spinner fa-spin'} background={'bg-warning'}
          onClickFunction={() => setServersFilter(ClientServerStatus.warning)}
        />

        <StatCard num={serverList.filter(s => s.status == ClientServerStatus.disconnected).length}
          text={'Offline'} icon={'fa-times'}
          onClickFunction={() => setServersFilter(ClientServerStatus.disconnected)}
        />
        {/* <StatCard num={2} text={'Offline'} icon={'fa-times'} background={'bg-gd-cherry'} /> */}

      </div>







      <div className="content-heading d-flex justify-content-between align-items-center">
        <span>
          Servers <small className="d-none d-sm-inline"></small>
        </span>
      </div>

      {readyState == ReadyState.OPEN &&
        <div className='row'>
          {serverList.length > 0 && serverList
            .filter((server) => server.status !== ClientServerStatus.unknown)
            .filter((server) => serversFilter != undefined ? server.status == serversFilter : server)
            .map((server: IClientServer, index: number) =>
              <ServerCard
                key={index}
                server={server}
                onPress={() => onServerClick(server)}
                />
            )

          }
        </div>
      }



      <div className="content-heading d-flex justify-content-between align-items-center">
        <span>
          Unknown Servers <small className="d-none d-sm-inline"></small>
        </span>
      </div>

      {readyState == ReadyState.OPEN &&
        <div className='row'>
          {serverList.length > 0 && serverList
            .filter((server) => server.status === ClientServerStatus.unknown)
            .map((server: IClientServer, index: number) =>
              <ServerCard
                key={index}
                server={server}
                onPress={() => onServerClick(server)}
              />
            )

          }
        </div>
      }



    </>
  )
}

export default DashboardPage