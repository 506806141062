import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { arrayBuffer } from 'stream/consumers'
import ServerCard from '../../components/ServerCard'
import { ClientServerStatus, iClient, IClientServer, iLicense } from '../../config/shared'
import { htmlSwal } from '../../config/template'
import { ConnectedClientsContext } from '../../hooks/WebSocketContext'
import { DebugObject } from '../../utils/Debug'
import { LicenseViewRoute } from '../Licenses/LicenseView'
import { viewClientsRequest } from './Client.requests'
import { ClientListRoute } from './ClientList'


export const ClientViewRoute = "/clients/view/:uuid"


function ClientView() {

    const { uuid } = useParams()

    const navigate = useNavigate();

    const [client, setClient] = useState<iClient | undefined | null>(undefined);
    const {serverList} = useContext(ConnectedClientsContext)

    const clientLicenses = client?.licenses?.map(l => l.uuid);
    const clientActiveServers = serverList.filter(s => clientLicenses?.includes(s.LicenseUuid))

    // const duplicates = clientActiveServers.filter((c,index)=> clientActiveServers.indexOf(c) !== index);




    const getClient = async () => {
        const { body, response } = await viewClientsRequest(uuid ?? "");
        response.ok ? setClient(body) : setClient(null);
    }

    useEffect(() => {
        getClient()
        return () => { setClient(undefined) }
    }, [])


    useEffect(() => {
        return () => { }
    }, [client])



    const onServerClick = async (server :IClientServer) => {
        console.log(server);
        
        
        htmlSwal(
            <div>
                <h3 
                    // onClick={(e)=> navigateTo(LicenseViewRoute.replace(":uuid",server.LicenseUuid))}
                >{server.LicenseUuid}</h3>

                <h4>{server.appname} - {server.clientname}</h4>
                <table className='table table-bordered'>
                    <tr>
                        <th>Endreço</th> <th>Porta</th> <th>Tipo</th> <th>Estado</th>
                    </tr>
                    <tr>
                        <td>{server.clientSocketInfo?.remoteAddress ?? ""}</td> 
                        <td>{server.clientSocketInfo?.remotePort  ?? ""}</td> 
                        <td>{server.clientSocketInfo?.remoteFamily  ?? ""}</td>
                        <td>{server.status}</td>
                    </tr>
                </table>
            </div>
            ,800
        )
        

    }


    //RENDER

    if (client == null) {
        return <> <h1>CLIENT NOT FOUND</h1> </>
    }

    return (
        <div>
            {/* info */}
            <div className="my-3 text-center">
                <h2 className="fw-bold mb-2">
                    {client.name}
                </h2>
                <h6 className="h6 text-muted mb-0">
                    {client.place}
                </h6>
            </div>



            <div className="content-heading d-flex justify-content-between align-items-center">
                <span>Servidores <small>({clientActiveServers.length})</small></span>
                <span>Licenças <small>({client.licenses?.length})</small></span>
            </div>



            <div className='row'>
                {clientActiveServers.length > 0 && clientActiveServers
                    // .filter((server) => serversFilter != undefined ? server.status == serversFilter : server)
                    .map((server: IClientServer, index: number) =>
                        <ServerCard
                            key={index}
                            server={server}
                            onPress={() => onServerClick(server)}
                        />
                    )

                }
            </div>



            {DebugObject(client, "client")}
            
        </div>
    )
}

export default ClientView