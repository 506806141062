import { ApplicationEndpoint, makeRequest, VersionEndpoint } from "../../config/endpoints";


export const findAllApplicationRequest = async () => 
    await makeRequest("get",ApplicationEndpoint);

export const createApplicationRequest = async (data: any) => 
    await makeRequest("post",ApplicationEndpoint,data);


export const viewApplicationRequest = async (id:string) => 
    await makeRequest("get",`${ApplicationEndpoint}/${id}`);


// TODO : 
export const deleteApplicationRequest = async (id:string) => 
    await makeRequest("delete",`${ApplicationEndpoint}/${id}`)
// {
//     // const response = await fetch(`${ApplicationEndpoint}/${id}`, {
//     //     method: "DELETE",
//     //     mode: 'cors',
//     //     headers: {
//     //         'Content-Type': 'application/json'
//     //     },
//     // })
//     // .then(res => res.json())
//     // .catch(err => {console.error(err); throw err});
//     // return response
    
// }


// Version Requests

export const createVersionRequest = async (data: any) => 
    await makeRequest("post", VersionEndpoint, data);


export const viewVersionRequest = async (id:string) =>
    await makeRequest("get",`${VersionEndpoint}/${id}`);


export const createVersionHashRequest = async (id:string,data:any) => 
    await makeRequest("post",`${VersionEndpoint}/${id}/createHash`,data);

export const deleteVersionHashRequest = async (id:string, hashUuid :string) =>
    await makeRequest("delete",`${VersionEndpoint}/${id}/removeHash/${hashUuid}`);


export const generateHashFileRequest = async (id :string, hashUuid :string) => 
    await makeRequest("get", `${VersionEndpoint}/${id}/generateHashFile/${hashUuid}`);