import React from 'react'


interface iTable {
    header: any[];
    renderTableBodyData: () => any;

    loading?: boolean;
    data?: any[];
    search?: string;
    setSearch?: (search: string) => void;
    serachLabel?: string;

    small? : boolean;
    striped? : boolean;
    bordered? :boolean;
}

function Table({ renderTableBodyData, header, loading, data, search, setSearch, serachLabel = "Procure...", small = false, striped = true, bordered=false }: iTable) {



    const renderTableBody = () => {
        if (loading) {
            return (
                <React.Fragment>
                    <tr>
                        <td className="bg-body-light animated fadeIn" colSpan={header.length}>
                            <div className="content content-full">
                                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                                    <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                                        <i className="fa fa-circle-notch fa-spin text-primary"></i>A
                                        obter dados do servidor...
                                    </h1>
                                </div>
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        }
        else if (!data || (data && data.length === 0)) {
            return (
                <React.Fragment>
                    <tr>
                        <td colSpan={5} className="p-0">
                            <div className="bg-body-light animated fadeIn">
                                <div className="content content-full">
                                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                                        <h4 className="flex-sm-fill font-size-h4 font-w400 mt-2 mb-0 mb-sm-2">
                                            Sem resultados
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        }
        else {

            return renderTableBodyData() ??
                (<React.Fragment>
                    <div className="bg-body-light animated fadeIn">
                        <div className="content content-full">
                            <h2>please define a render method</h2>
                        </div>
                    </div>
                </React.Fragment>)
        }
    }

    return (
        <div>
            {setSearch &&

                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.code === "Escape" || e.code === "Enter" || e.code === "NumpadEnter") {
                                e.currentTarget.blur()
                            }
                        }}
                        value={search}
                        placeholder={serachLabel}
                    />
                    <span className="btn bg-dark text-white" >
                        <i className="fa fa-fw fa-search"></i>
                    </span>
                </div>
            }

            <div className="block block-rounded">
                <div className="block-content p-0">
                    <div className='table-responsive'>
                    <table className={`table ${striped &&"table-striped"} ${bordered &&"table-bordered"} table-vcenter ${small && "table-sm"}`}>
                        <thead>
                            <tr>
                                {
                                    header && header.map((head) => {
                                        return (<th key={head.name} className={head.style ?? ""}>{head.name}</th>)
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>{renderTableBody()}</tbody>
                    </table>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Table
