import React, { useContext, useEffect, useRef, useState } from 'react'

import * as monaco from 'monaco-editor';
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import { UserContext } from '../hooks/UserContext';

import { Control, Controller, RefCallBack, UseFormClearErrors, UseFormReturn, UseFormSetError } from 'react-hook-form';


interface iJsonEditorProps {
    ref?: RefCallBack,
    onChange: (newCode: string | undefined, event: monaco.editor.IModelContentChangedEvent) => void,
    name: string

    setErrorProxy?: UseFormSetError<any>,
    clearErrorProxy?: UseFormClearErrors<any>
    // value?: object | string,
    value?: string,

}

function JsonEditor({
    value = "{}",
    ref,
    onChange,
    name,
    setErrorProxy,
    clearErrorProxy

}: iJsonEditorProps) {

    const { darkMode } = useContext(UserContext)

    // const [jsonvalue, setJsonvalue] = useState<string>(JSON.stringify(value, null, 2))
    // const [jsonvalue, setJsonvalue] = useState<object|undefined>(obj)
    const [error, setError] = useState<monaco.editor.IMarker[]>([])


    const monaco = useMonaco();

    useEffect(() => {
        if (monaco) {
            console.log("here is the monaco instance:", monaco);
        }
    }, [monaco]);

    // const change = (newCode: string|undefined, event: monaco.editor.IModelContentChangedEvent) => {
    //     // setJsonvalue(JSON.parse(newCode!))
    //     setJsonvalue(newCode!)
    // }

    function handleEditorValidation(markers: monaco.editor.IMarker[]) {
        // model markers
        markers.forEach(marker => console.log('onValidate:', marker.message));
        setError(markers);

        clearErrorProxy && clearErrorProxy(name)

        markers.length > 0 && setErrorProxy &&  setErrorProxy(name, {
            message: "Invalid Json",
            type: "value"
        })
    }


    return (
        <>
            <Editor
                className={`JsonEditor ${error.length > 0 && "border-danger"}`}
                height="20vh"
                defaultLanguage="json"
                value={typeof value == "string" ? value : JSON.stringify(value, null, 2)}
                theme={darkMode ? "vs-dark" : "light"}
                // onChange={change}
                onChange={onChange}
                options={{
                    minimap: { enabled: false },
                    renderWhitespace: "all",
                    formatOnPaste: true,
                    formatOnType: true,
                }}

                onValidate={handleEditorValidation}
            />

            {/* <span>{(jsonvalue)}</span> */}
            {/* <span>{JSON.stringify(jsonvalue)}</span> */}
        </>

    )
}

export default JsonEditor



interface iJsonEditorControlledProps {
    name:string,
    form: UseFormReturn<any, any>
}
export function JsonEditorControlled({
    name,
    form
}: iJsonEditorControlledProps){

    const watchForm = form.watch();

    return (
        <Controller
            name={name}
            control={form.control}
            render={(input) =>
                <JsonEditor
                    // ref={input.field.ref}
                    onChange={ (val) => input.field.onChange(val) }
                    name={input.field.name}
                    setErrorProxy={form.setError}
                    clearErrorProxy={form.clearErrors}
                    value={watchForm[name] || undefined}
                />
            }
        />
    )
}