import { appendErrors, Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { JsonEditorControlled } from '../../components/JsonEditor';
import ReactSelectControlled from '../../components/ReactSelect';
import { showRequestError } from '../../config/endpoints';


import { ApplicationConnectionType, ApplicationType, versionRegex } from '../../config/shared';
import { errorSwal } from '../../config/template';
import { DebugObject } from '../../utils/Debug';
import { ISelectLabelValue } from '../Licenses/LicenseCreate';
import { createApplicationRequest } from './Application.requests';
import { ApplicationViewRoute } from './ApplicationView';

export const ApplicationCreateRoute = "/applications/create"



type Inputs = {
    name: string,
    applicationType: ApplicationType,
    applicationConnectionType: ApplicationConnectionType,


    version: string,
    // parameters: Params[],
    parameters: string,

    _applictionType: ISelectLabelValue;
    _applicationConnectionType: ISelectLabelValue

};

function ApplicationCreate() {

    const navigate = useNavigate();

    const form = useForm<Inputs>({
        mode: "all",
        defaultValues: {
            name: "",
            applicationType: ApplicationType.NEST,
            applicationConnectionType: ApplicationConnectionType.WEBSOCKET,

            version: "1.0.0",
            parameters: "{}",

        },
    });

    const { register, control, handleSubmit, watch, formState: { errors,isValid,isValidating }, setValue, resetField } 
    = form 

    // const parameters = useFieldArray({ control: control, name: "parameters" })
    const watchForm = watch();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        if(!isValid) return;

        
        data.applicationType = ApplicationType[data._applictionType.value as ApplicationType];
        data.applicationConnectionType = ApplicationConnectionType[data._applicationConnectionType.value as ApplicationConnectionType];

        // data.parameters = JSON.parse(data.parameters)

        const { body, response } = await createApplicationRequest(data);

        if (response.ok && body.uuid) {
            navigate(ApplicationViewRoute.replace(":uuid", body.uuid))
        }
        else {
            showRequestError({ body, response })
        }
    };




    const applicationTypeOptions: ISelectLabelValue[] = Object.keys(ApplicationType).map((v, index) => {
        return { label: v, value: v }
    });

    const applicationConnectionTypeOptions: ISelectLabelValue[] = Object.keys(ApplicationConnectionType).map((v, index) => {
        return { label: v, value: v }
    });



    


    return (
        <>
            <h2 className="content-heading">{watchForm.name || "Criar Applicação"}</h2>
            <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
                {/* info */}
                <div className='block block-rounded'>
                    <div className='block-header block-header-default'>
                        <h3 className="block-title">Informações</h3>
                    </div>

                    <div className='block-content'>

                        {/* name */}
                        <div className="mb-4">
                            <label className="form-label" htmlFor="name">Nome</label>
                            <input type="text" className={`form-control ${errors.name && "is-invalid"}`}
                                placeholder="Nome da Applicação"
                                {...register("name", { required: true, value: "", minLength: 5 })} />
                            {errors.name && <div className="invalid-feedback">Nome Inválido</div>}
                        </div>


                        {/* Type */}
                        <div className='mb-4'>
                            <label className="form-label" htmlFor="client">Tipo de Applicação</label>
                            <ReactSelectControlled name='_applictionType' form={form} options={applicationTypeOptions} />
                            {errors._applictionType && <div className="invalid-feedback">{errors._applictionType?.message}</div>}
                        </div>

                        {/* ConnectionType */}
                        <div className='mb-4'>
                            <label className="form-label" htmlFor="client">Tipo de Conexão</label>
                            <ReactSelectControlled name='_applicationConnectionType' form={form} options={applicationConnectionTypeOptions} />
                            {errors._applicationConnectionType && <div className="invalid-feedback">{errors._applicationConnectionType?.message}</div>}
                        </div>

                    </div>
                </div>

                {/* version */}
                <div className='block block-rounded'>
                    <div className='block-header block-header-default'>
                        <h3 className="block-title">Versão Inicial</h3>
                    </div>

                    <div className='block-content'>

                        <div className="mb-4">
                            <label className="form-label" htmlFor="name">Versão</label>
                            <input type="text" className={`form-control ${errors.version && "is-invalid"}`}
                                placeholder="Número da Versão Inicial"
                                {...register("version", { required: true, minLength: 1, pattern: versionRegex })} />
                            {errors.name && <div className="invalid-feedback">Versão Inválida</div>}
                        </div>


                        {/* <div className='mb-4'>
                            <label className="form-label" htmlFor="textarea">Parâmetros</label>
                            <textarea className={`form-control ${errors.parameters && "is-invalid"}`} rows={5}
                                {...register("parameters")} />
                        </div> */}

                        <div className='mb-4'>
                            <label className="form-label" htmlFor="textarea">Parâmetros</label>
                            <JsonEditorControlled
                                name={'parameters'}
                                form={form}
                            />
                            {errors.parameters && <div className="invalid-feedback d-block">{errors.parameters?.message}</div>}
                            
                        </div>

                    </div>
                </div>


                <div className="mb-4 ">
                    <button type="submit" className='btn btn-primary' >
                        Submeter
                    </button>
                </div>

            </form>

            <DebugObject object={watchForm}/>

        </>
    )
}

export default ApplicationCreate