import { editor } from 'monaco-editor';
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ErrorOption } from 'react-hook-form';
import { Link } from 'react-router-dom';
import JsonEditor from '../../components/JsonEditor';
import Table from '../../components/Table';
import { getSysLogsRequest, getSysSignObject, LoggingEndpoint, makeRequest, showRequestError } from '../../config/endpoints';
import { LogType } from '../../config/shared';
import { UserContext } from '../../hooks/UserContext';
import { sleep } from '../../utils/Sleep';
import { DashboardPageRoute } from '../DashboardPage';

export const SettingsPageRoute = "/settings"


function SettingsPage() {

    const { darkMode } = useContext(UserContext)


    return (
        <div>
            <h1>Settings Page</h1>
            <div className="content content-full">
              <div className="py-4 text-center">
                <i className="si si-chemistry text-primary display-3"></i>
                <h1 className="h2 fw-bold mt-4 mb-2">Under Construction!</h1>
                <h2 className="h5 fw-medium text-muted mb-5"></h2>
                <Link className="btn btn-primary" to={DashboardPageRoute}>
                  <i className="fa fa-arrow-left opacity-50 me-1"></i> Go Back to Dashboard
                </Link>
              </div>
            </div>
        </div>
    )
}

export default SettingsPage