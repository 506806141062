import React from 'react'

function Footer() {
  return (
    // <!-- Footer -->
    <footer id="page-footer">
      <div className="content py-3">
        <div className="row fs-sm">
          <div className="col-sm-6 order-sm-1 py-1 text-center text-sm-start">
            <a className="fw-semibold" href="https://empty.pt/" target="_blank">Empty</a> 
            &nbsp;&copy;&nbsp; 
            <span data-toggle="year-copy"></span>
          </div>
        </div>
      </div>
    </footer>
    // <!-- END Footer -->  
  )
}

export default Footer