import React, { useContext, useEffect } from 'react';
import './App.css';

import Sidebar from "./layouts/Sidebar"
import Header from "./layouts/Header"
import Footer from "./layouts/Footer"
import SideOverlay from './layouts/SideOverlay';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from './config/routes';
import Page404 from './pages/Page404';
import { DashboardSocketProvider } from './hooks/WebSocketContext';
import { initCodebase, sidebar_close, sidebar_open } from './config/template';
import { UserContext, UserProvidor } from './hooks/UserContext';
import LoginPage from './pages/LoginPage';
// import { socketUrl, WebSocketProvider,  } from './hooks/WebSocketContext';

function App() {


  const { accessToken, user } = useContext(UserContext)

  const LoggedIn = accessToken && accessToken.length > 0 && !!user;


  useEffect(() => {
    if (LoggedIn) {
      initCodebase();
      initCodebase(); // TODO : Problema Estranho
      console.log("CODEBASE");

    }
  }, [LoggedIn])


  if (!LoggedIn) {
    return (
      <Routes>
        <Route key={"login"} path="*" element={<LoginPage />} />
      </Routes>
    )
  }



  return (
    <>
      <DashboardSocketProvider>

        <SideOverlay />
        <Sidebar />
        <Header />

        <main id="main-container">
          <div className="content">

            {LoggedIn &&

              <Routes>

                {routes.map((router, index) => {
                  return (
                    <Route
                      key={`router${index}`}
                      path={router.path}
                      element={<router.element />}
                    />
                  );
                })}
                <Route key={"index"} path="/" element={<Navigate to={'/dashboard'} replace />} />

                <Route key={404} path="*" element={<Page404 />} />
              </Routes>
            }


          </div>
        </main>

        <Footer />
      </DashboardSocketProvider>

    </>
  );
}

function AppWrapper() {
  return (
    <UserProvidor>
      <App />
    </UserProvidor>

  )
}

export default AppWrapper;
