import { editor } from 'monaco-editor';
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ErrorOption } from 'react-hook-form';
import CopyButton from '../../components/CopyButton';
import JsonEditor from '../../components/JsonEditor';
import Table from '../../components/Table';
import { getSysLogsRequest, getSysSignObject, LoggingEndpoint, makeRequest, showRequestError } from '../../config/endpoints';
import { LogType } from '../../config/shared';
import { UserContext } from '../../hooks/UserContext';
import { sleep } from '../../utils/Sleep';

export const SignObjectPageRoute = "/SignObject"


function SignObjectPage() {

    const { darkMode } = useContext(UserContext)


    const [loading, setLoading] = useState(true)
    const [textInput, setTextInput] = useState<string|undefined>(undefined);
    const handleChange = (val:string|undefined) => setTextInput(val)
    const [error, setError] = useState<string | null>(null)
    const [result, setResult] = useState<string>("")



    const run = async () => {
        if(typeof textInput == "undefined")
            return;
        
        setError(null)
        try {
            JSON.parse(textInput)
        } catch (error) {
            setError("Bad Json Object")
            return;
        }

        const res = await getSysSignObject({ jsonstring: textInput })

        if (!res.response.ok) {
            showRequestError(res);
        }

        const jsonString = JSON.stringify(res.body, null, 2)
        setResult(jsonString)
    }

    return (
        <div>
            <h1>Sign Object</h1>

            <div className='mb-4'>
                <label className="form-label" htmlFor="textarea">Object</label>
                <JsonEditor 
                    onChange={handleChange} 
                    name={'editor'}
                    value={textInput}
                />

                {error && <div className="invalid-feedback">{error}</div>}

            </div>

            <div className="mb-4 ">
                <button type="button" className='btn btn-primary' onClick={run}>
                    Submeter
                </button>
            </div>


            <textarea
                className="form-control my-2"
                rows={15}
                value={result}
                disabled />



            <CopyButton 
                content={result}
                className="btn btn-secondary"
            />

        </div>
    )
}

export default SignObjectPage