import { useContext, useEffect, useState, } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import CopyButton from '../../components/CopyButton';
import { changeUserPassword, getDeveloperLicenseRequest, showRequestError, updateDeveloperLicenseRequest } from '../../config/endpoints';
import { MySwal } from '../../config/template';
import { UserContext } from '../../hooks/UserContext';
import { randomIntFromInterval } from '../../utils/Math';
import { DashboardPageRoute } from '../DashboardPage';

export const ProfilePageRoute = "/profile"


type PasswordChangeInput = {
    oldPassword: string,
    newPassword: string,
    newPassword2: string,
}

const imageId = randomIntFromInterval(1, 43);


function ProfilePage() {

    const { darkMode, user, accessToken } = useContext(UserContext);

    const [developerLicense, setDeveloperLicense] = useState(null);

    const getDeveloperLicense = async () => {
        const { body, response } = await getDeveloperLicenseRequest();
        if (response.ok)
            setDeveloperLicense(body)
        else
            showRequestError({ body, response })
    }

    useEffect(() => { getDeveloperLicense() }, [])

    const updateDeveloperLicense = async () => {
        const { body, response } = await updateDeveloperLicenseRequest();
        if (response.ok) {
            MySwal.fire({ icon: "success", text: "Developer License Updated" })
            setDeveloperLicense(body);
        }
        else
            showRequestError({ body, response })
    }


    const passwordForm = useForm<PasswordChangeInput>({ mode: "all" });
    const { register, handleSubmit, formState: { errors, isValid } } = passwordForm
    const [updatingPassword, setUpdatingPassword] = useState<boolean>(false)

    const onPasswordChangeSubmit: SubmitHandler<PasswordChangeInput> = async (data) => {
        if (!isValid) return;

        setUpdatingPassword(true);
        const { body, response } = await changeUserPassword(data);

        if (response.ok) {
            passwordForm.reset()
            passwordForm.clearErrors()
            MySwal.fire({ icon: "success", })
        }
        else {
            showRequestError({ body, response })
        }

        setUpdatingPassword(false);
    }

    return (
        <>
            <div
                className="bg-image bg-image-bottom"
                style={{ backgroundImage: `url('assets/media/photos/photo${imageId}@2x.jpg')` }}
            >
                <div className="bg-primary-dark-op py-4">
                    <div className="content content-full text-center">


                        {/* <!-- Avatar --> */}
                        <div className="mb-3">
                            {/* <a className="img-link" href="be_pages_generic_profile.html"> */}
                            <img className="img-avatar img-avatar96 img-avatar-thumb" src="assets/media/avatars/avatar15.jpg" alt="" />
                            {/* </a> */}
                        </div>


                        {/* <!-- Personal --> */}
                        <h1 className="h3 text-white fw-bold mb-2 text-capitalize">
                            {user?.name}
                        </h1>
                        <h2 className="h5 fw-medium text-white-75 text-capitalize">
                            software developer
                        </h2>


                        {/* <!-- Actions --> */}
                        {/* <button type="button" className="btn btn-primary me-1">
                <i className="fa fa-fw fa-envelope opacity-50 me-1"></i> Message
              </button>
              <a className="btn btn-alt-primary" href="be_pages_generic_profile_edit.html">
                <i className="fa fa-fw fa-pencil-alt opacity-50 mb-1"></i> Edit
              </a> */}




                    </div>
                </div>
            </div>

            {/* Access Token */}
            <div className="content-heading d-flex justify-content-between align-items-center">
                <span>
                    Access Token
                </span>
            </div>

            <div className="row items-push">
                <div className='col-12 text-warp text-break'>
                    {accessToken}

                    <div className='col text-center mt-2'>
                        <CopyButton
                            content={accessToken!}
                        />
                    </div>

                </div>

            </div>

            {/* Developer License */}
            {developerLicense != null && (<>
                <div className="content-heading d-flex justify-content-between align-items-center">
                    <span>
                        Developer License
                    </span>
                </div>

                <div className="row items-push">
                    <div className='col-12 text-warp text-break'>
                        {JSON.stringify(developerLicense) != "{}" &&
                            // <pre style={{ whiteSpace: "pre-wrap"}}>{JSON.stringify(developerLicense, null, 2)}</pre>
                            <>
                                <code>~/.developer.empty</code>
                                <textarea
                                    className="form-control mb-4"
                                    rows={10}
                                    value={JSON.stringify(developerLicense, null, 2)}
                                    disabled />
                            </>

                        }

                        <div className='col text-center mt-2'>
                            {JSON.stringify(developerLicense) != "{}" &&
                                <CopyButton content={JSON.stringify(developerLicense, null, 2)!} />
                            }
                            <button className='btn btn-sm btn-warning mx-2' onClick={updateDeveloperLicense}>
                                Update License
                            </button>
                        </div>
                    </div>
                </div>

            </>)}



            {/* Password */}
            <div className="content-heading d-flex justify-content-between align-items-center">
                <span>
                    Password
                </span>
            </div>


            <form onSubmit={handleSubmit(onPasswordChangeSubmit)} encType='multipart/form-data'>
                <div className="row items-push mb-2">

                    {/* antiga */}
                    <div className="mb-4 col-md-4">
                        <label className='form-label' htmlFor='oldPassword'>Antiga</label>
                        <input type="password" className={`form-control ${errors.oldPassword && "is-invalid"}`}
                            placeholder="Password"
                            autoComplete='current-password'
                            {...register("oldPassword", { required: true, value: "", minLength: 5 })} />

                        {errors.oldPassword && <div className="invalid-feedback">Inválido</div>}
                    </div>

                    {/* nova */}
                    <div className="mb-4 col-md-4">
                        <label className='form-label' htmlFor='newPassword'>Nova</label>
                        <input type="password" className={`form-control ${errors.newPassword && "is-invalid"}`}
                            placeholder="Password"
                            autoComplete='new-password'
                            {...register("newPassword", { required: true, value: "", minLength: 5 })} />
                        {errors.newPassword && <div className="invalid-feedback">Inválido</div>}
                    </div>

                    {/* confirmação */}
                    <div className="mb-4 col-md-4">
                        <label className='form-label' htmlFor='newPassword2'>Confirmação</label>
                        <input type="password" className={`form-control ${errors.newPassword2 && "is-invalid"}`}
                            placeholder="Password"
                            autoComplete='new-password'
                            {...register("newPassword2", { required: true, value: "", minLength: 5 })} />
                        {errors.newPassword2 && <div className="invalid-feedback">Inválido</div>}
                    </div>


                    <button type="submit" className='btn btn-primary' disabled={updatingPassword}>
                        Submeter
                    </button>
                </div>
            </form>



        </>
    )
}

export default ProfilePage