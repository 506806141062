import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ServerCard from '../../components/ServerCard';
import Table from '../../components/Table';
import { iApplication, IClientServer, iVersion } from '../../config/shared';
import { htmlSwal } from '../../config/template';
import { ConnectedClientsContext } from '../../hooks/WebSocketContext';
import { DebugObject } from '../../utils/Debug';
import { parseParamsToText } from '../../utils/Params';
import { LicenseViewRoute } from '../Licenses/LicenseView';
import { viewApplicationRequest } from './Application.requests';
import { ApplicationCreateVersionRoute } from './ApplicationCreateVersion';
import { VersionViewRoute } from './VersionView';

export const ApplicationViewRoute = "/applications/view/:uuid"


function ApplicationView() {

    const { uuid } = useParams()
    const [app, setApp] = useState<iApplication | undefined | null>(undefined)

    const { serverList } = useContext(ConnectedClientsContext)
    const appServers = serverList.filter(s => s.appname === app?.name)

    const navigate = useNavigate();



    const getApp = async () => {
        const {body, response} = await viewApplicationRequest(uuid ?? "");
        response.ok ? setApp(body) : setApp(null);
    }

    useEffect(() => {
        getApp()
        return () => { setApp(undefined) }
    }, [])




    //version table
    const checkVersionParameters = (version: iVersion) => {
        // const text = parseParamsToText(version.parameters)
        const text = JSON.stringify(version.parameters,null,2)

        const arr = Object.entries(version.parameters);
        const size = arr.length < 10 ? 10 : arr.length + 1;


        htmlSwal(
            <>
                <h2>{version.number}</h2>
                <textarea
                    className="form-control"
                    style={{ resize: "none" }}
                    rows={size}
                    value={text}
                    disabled />
            </>
        )
    }





    const AppVersionTableHeader = [
        { name: "#", style: "text-center" },
        { name: "Criado", style: "text-center" },
        { name: "Por", style: "text-center d-none d-sm-table-cell" },
        { name: "Parâmetros", style: "text-center" },
        { name: "Ações", style: "text-center" },
    ];

    const renderAppVersionTableBody = () => {
        return app?.versions?.map((version: iVersion) => {
            return (
                <tr key={version?.uuid}>
                    <td className="text-center">{version.number}</td>
                    <td className="text-center">{new Date(version?.createdAt).toDateString()}</td>
                    <td className="text-center d-none d-sm-table-cell">{version.createdBy}</td>
                    <td className="text-center">
                        {/* temp */}
                        {Object.entries(version.parameters).length > 0
                            ?
                            <button className="btn btn-sm btn-info" onClick={() => checkVersionParameters(version)}>
                                <i className="fa fa-fw fa-eye"></i> Ver
                            </button>
                            :
                            <small>Sem Parâmetros</small>
                        }
                    </td>

                    <td className="text-center">    
                        <Link type="button" className="btn btn-sm btn-primary" to={
                            VersionViewRoute.replace(":uuid",app.uuid).replace(":versionUuid", version.uuid)}>
                            <i className="fa fa-fw fa-eye"></i> Ver
                        </Link>
                    </td>
                </tr>
            )
        })
    }



    const onServerClick = (server: IClientServer) => {
        const url = LicenseViewRoute.replace(":uuid", server.LicenseUuid);
        navigate(url)
    }


    // RENDERS

    if (app == null) {
        return <> <h1>APP NOT FOUND</h1> </>
    }

    return (
        <div>

            {/* info */}
            <div className="my-3 text-center">
                <h2 className="fw-bold mb-2">
                    {app.name}
                </h2>
                <h3 className="h5 text-muted mb-0">
                    {/* {app.uuid} */}
                    ({app.applictionType} - {app.applicationConnectionType})
                </h3>
            </div>



            {/* VERSION TABLE */}
            <div className='push'>

                <div className="content-heading d-flex justify-content-between align-items-center">
                    <span> Versões <small>({app?.versions?.length})</small></span>
                    <span>
                        <Link type="button" className="btn btn-sm btn-primary" to={ApplicationCreateVersionRoute.replace(":uuid", app.uuid)}>
                            <i className="fa fa-fw fa-plus"></i> Nova
                        </Link>
                    </span>
                </div>

                <div className="block block-rounded h-100">
                    <div className='block-content'>
                        <Table
                            key={"versionTable"}
                            header={AppVersionTableHeader}
                            renderTableBodyData={renderAppVersionTableBody}
                            data={app.versions}
                        />
                    </div>
                </div>

            </div>




            {/* SERVER LIST */}
            <div className='row push'>

                <div className="content-heading d-flex justify-content-between align-items-center">
                    <span> Servidores <small>({appServers.length})</small></span>

                </div>

                {appServers.length > 0 && appServers
                    // .filter((server) => serversFilter != undefined ? server.status == serversFilter : server)
                    .map((server: IClientServer, index: number) =>
                        <ServerCard
                            key={index}
                            server={server}
                            onPress={() => onServerClick(server)}
                        />
                    )

                }
            </div>



            {DebugObject(app, "app")}

        </div>
    )
}

export default ApplicationView
