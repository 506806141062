import { useState, createContext, useContext, ReactChild, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { DashboardEvents, IClientServer, MessageFormat } from "../config/shared";


// TODO : share 
export interface IUserProfile {
  username :string;
  name :string,
  email :string,
  uuid :string
}



export interface userContextProviderValues {
  darkMode: boolean,
  darkmode_toggle: Function,

  accessToken :string|null,
  user :IUserProfile|null,
  setUserAccount(token :string ,user :IUserProfile) :void,
  logout() :void,
}

export const UserContext = createContext<userContextProviderValues>({
  darkMode: localStorage.getItem("codebaseDarkMode") != null,
  darkmode_toggle: () => {},


  accessToken :"",
  user : null,
  setUserAccount: () => {},
  logout: () => {},

});



export const UserProvidor = (props: any) => {

  const [user, setUser] = useState<IUserProfile|null>(localStorage.getItem("user") 
    ? JSON.parse( localStorage.getItem("user")! )
    : null
  );
  const [accessToken, setAccessToken] = useState<string|null>(localStorage.getItem("access_token"));

  const [darkMode, setDarkMode] = useState<boolean>(localStorage.getItem("codebaseDarkMode") != null);
  

  localStorage.getItem("user")

  useEffect(() => {
    // BUG : ativar dark mode e depois desativar fica com o swal em darkmode
    if (darkMode) {
      require("@sweetalert2/themes/dark")
    }
    else {
      require("@sweetalert2/themes/bootstrap-4")
    }
  }, [darkMode])


  const darkmode_toggle = () => {
    (window as any).Codebase.layout('dark_mode_toggle');
    setDarkMode(localStorage.getItem("codebaseDarkMode") != null);
  }





  const setUserAccount = (token :string ,user : IUserProfile) => {
    localStorage.setItem("access_token",token)
    setAccessToken(token);
    localStorage.setItem("user",JSON.stringify(user))
    setUser(user)
  }

  const logout = () => {
    localStorage.removeItem("access_token")
    setAccessToken(null);
    localStorage.removeItem("user")
    setUser(null)
  }


  return (
    <UserContext.Provider value={{
        darkMode, 
        darkmode_toggle, 
        
        accessToken,
        user,
        setUserAccount,
        logout 
      }}>
      {props.children}
    </UserContext.Provider>
  )
}