import { Link, useLocation } from "react-router-dom"
import { iApplication, iClient, iLicense, isLicenseValid } from "../../config/shared"
import { ApplicationViewRoute } from "../Applications/ApplicationView";
import { ClientViewRoute } from "../Clients/ClientView";
import { LicenseViewRoute } from "../Licenses/LicenseView";

export const SearchPageRoute = "/search"


interface iSearchResult {
    clients: iClient[],
    applictions: iApplication[],
    licenses: iLicense[],

}

interface iSearch {
    searchTerm: string,
    response: iSearchResult,
}


function SearchPage() {

    const location = useLocation();

    if (!location.state) {
        return <div>
            <h2>
                Não Procurou!
            </h2>
        </div>
    }

    const { searchTerm, response } = location.state as iSearch;

    const resultsCount = response.clients.length + response.applictions.length + response.licenses.length ?? 0;

    return (
        <div>
            <h2>
                <span className="text-primary fw-bold">{resultsCount}</span>
                &nbsp;resultado para&nbsp;
                <span className="text-danger fw-bold">{searchTerm}</span>
            </h2>

            <div>

                {response.applictions.length > 0 &&
                    <>
                        <h3 className="py-3 border-bottom">Applicações</h3>
                        {response.applictions.map((app: iApplication) =>
                            <h4>
                                <Link type="button" className=""
                                    to={ApplicationViewRoute.replace(":uuid", app.uuid)}>
                                    {app.name}
                                    &nbsp;
                                    {app.deletedAt !== null && <span className={`badge bg-danger f`}> Apagado</span>}
                                </Link>

                                <div className="fs-sm text-earth mb-1">{app.uuid}</div>
                            </h4>

                        )}
                    </>
                }


                {response.licenses.length > 0 &&
                    <>
                        <h3 className="py-3 border-bottom">Licenças</h3>
                        {response.licenses.map((license: iLicense) =>
                            <h4>
                                <Link type="button" className=""
                                    to={LicenseViewRoute.replace(":uuid", license.uuid)}>
                                    {license.uuid}
                                    &nbsp;
                                    {!isLicenseValid(license) && <span className={`badge bg-danger f`}> Inválida</span>}
                                </Link>
                            </h4>

                        )}
                    </>
                }


                {response.clients.length > 0 &&
                    <>
                        <h3 className="py-3 border-bottom">Clientes</h3>
                        {response.clients.map((client :iClient) =>
                            <h4>
                                <Link type="button" className=""
                                    to={ClientViewRoute.replace(":uuid", client.uuid)}>
                                    {client.name}
                                    &nbsp;
                                    {client.deletedAt && <span className={`badge bg-danger f`}> Apagado</span>}
                                </Link>

                                <div className="fs-sm text-earth mb-1">{client.uuid}</div>
                            </h4>

                        )}
                    </>
                }


            </div>
        </div>
    )
}

export default SearchPage